import { Action, Dispatch } from "redux";
import { EnhancedStore } from "@reduxjs/toolkit";
import * as Sentry from "@sentry/react";
import {
  AreaOfInterest,
  Language,
  UserCreate,
  UserPatch,
} from "../../client/api";
import client from "../../client";
import { Favorite, FavoriteType, IDType } from "../../config/types";
import { UserActions, UserActionTypes } from "./types";
import { defaultGet, fetchError } from "./all";
import { checkMail, errorLogging } from "../../config/utils";
import { AGB_LEVEL, AS_AOI, AS_LANG } from "../../config/constants";

export function fetchMe(dispatch: Dispatch<Action<UserActionTypes>>): Action {
  if (client().loggedIn()) {
    client()
      .me()
      .then(
        (response) => {
          client().setLang(response.language.locale);
          dispatch({
            type: UserActionTypes.FETCH_SUCCESS,
            payload: response,
          });
        },
        () => {
          dispatch({
            type: UserActionTypes.UNAUTHORIZED,
          });
        }
      );
    return dispatch({ type: UserActionTypes.FETCH_ME });
  }
  return dispatch({
    type: UserActionTypes.FETCH_ERROR,
    payload: "not_registered",
  });
}

export function register(
  dispatch: Dispatch<Action<UserActionTypes>>,
  user: UserCreate
) {
  defaultGet(
    dispatch,
    UserActionTypes.FETCH_SUCCESS,
    UserActionTypes.FETCH_ERROR,
    UserActionTypes.REGISTER,
    client().register,
    false,
    false,
    user
  );
}

export function refreshToken(dispatch: Dispatch<Action<UserActionTypes>>) {
  dispatch({ type: UserActionTypes.REFRESH });
  client()
    .refreshToken()
    .then(
      (response) => {
        dispatch({
          type: UserActionTypes.FETCH_SUCCESS,
          payload: response,
        });
      },
      (_) => {
        dispatch({
          type: UserActionTypes.FETCH_ERROR,
          payload: "not_registered",
        });
      }
    );
}

export function initBaseData(
  dispatch: Dispatch<Action<UserActionTypes>>,
  store: EnhancedStore
) {
  dispatch({ type: UserActionTypes.GET_BASE_DATA });
  const aoi = localStorage.getItem(AS_AOI);
  const language = localStorage.getItem(AS_LANG);
  if (aoi && language) {
    client()
      .getAoi(parseInt(aoi, 10), store)
      .then(
        (aoiObject) => {
          client()
            .getLanguage(parseInt(language, 10))
            .then(
              (languageObject) => {
                try {
                  dispatch({
                    type: UserActionTypes.BASE_DATA_SUCCESS,
                    payload: {
                      areaOfInterest: aoiObject,
                      language: languageObject,
                    },
                  });
                } catch (e) {
                  dispatch({
                    type: UserActionTypes.BASE_DATA_SUCCESS,
                    payload: {
                      areaOfInterest: aoiObject,
                      favorites: [],
                      language: languageObject,
                    },
                  });
                }
              },
              (error) => {
                fetchError(error, UserActionTypes.FETCH_ERROR, dispatch);
              }
            );
        },
        (error) => {
          fetchError(error, UserActionTypes.FETCH_ERROR, dispatch);
        }
      );
  } else {
    errorLogging({ aoi, language }, Sentry.Severity.Warning);
    dispatch({
      type: UserActionTypes.BASE_DATA_MISSING,
    });
  }
}

export function addFavorite(
  dispatch: Dispatch<Action<UserActionTypes>>,
  favorite: Favorite
) {
  defaultGet(
    dispatch,
    UserActionTypes.ADD_FAVORITE_SUCCESS,
    UserActionTypes.ADD_FAVORITE_FAILED,
    UserActionTypes.ADD_FAVORITE,
    client().addFavorite,
    false,
    false,
    favorite
  );
}

export function removeFavorite(
  dispatch: Dispatch<Action<UserActionTypes>>,
  id: IDType,
  type: FavoriteType
) {
  defaultGet(
    dispatch,
    UserActionTypes.ADD_FAVORITE_SUCCESS,
    UserActionTypes.ADD_FAVORITE_FAILED,
    UserActionTypes.ADD_FAVORITE,
    client().removeFavorite,
    false,
    false,
    { id, type }
  );
}

export function setAoi(
  dispatch: Dispatch<Action<UserActionTypes>>,
  aoi: AreaOfInterest
) {
  localStorage.setItem(AS_AOI, aoi.id.toString(10));
  dispatch({ type: UserActionTypes.SET_AOI });
  dispatch({
    type: UserActionTypes.SET_AOI_SUCCESS,
    payload: aoi,
  });
}

export function setLanguage(
  dispatch: Dispatch<Action<UserActionTypes>>,
  language: Language
) {
  localStorage.setItem(AS_LANG, language.id.toString(10));
  dispatch({ type: UserActionTypes.SET_LANGUAGE });
  dispatch({
    type: UserActionTypes.SET_LANGUAGE_SUCCESS,
    payload: language,
  });
}

export function changeLanguage(
  dispatch: Dispatch<Action<UserActionTypes>>,
  language: Language
) {
  localStorage.setItem(AS_LANG, language.id.toString(10));
  client().setLang(language.locale);
  defaultGet(
    dispatch,
    UserActionTypes.PATCH_SUCCESS,
    UserActionTypes.PATCH_FAILED,
    UserActionTypes.PATCH_USER,
    client().changeLanguage,
    false,
    false,
    language.id
  );
}

export function patchUser(
  dispatch: Dispatch<Action<UserActionTypes>>,
  user: UserPatch
) {
  const patchItself = () => {
    defaultGet(
      dispatch,
      UserActionTypes.PATCH_SUCCESS,
      UserActionTypes.PATCH_FAILED,
      UserActionTypes.PATCH_USER,
      client().patchUser,
      false,
      false,
      user
    );
  };
  if (user.email) {
    if (checkMail(user?.email ?? "")) {
      client()
        .emailAvailable(user?.email ?? "")
        .then(
          (response) => {
            if (response) {
              patchItself();
            } else {
              dispatch({
                type: UserActionTypes.REGISTER_FORM_INVALID,
                payload: "mailGiven",
              });
            }
          },
          () => {
            dispatch({
              type: UserActionTypes.REGISTER_FORM_INVALID,
              payload: "mailGiven",
            });
          }
        );
    } else {
      dispatch({
        type: UserActionTypes.REGISTER_FORM_INVALID,
        payload: "mailInvalid",
      });
    }
  } else {
    patchItself();
  }
}

export function changeAoi(
  dispatch: Dispatch<Action<UserActionTypes>>,
  aoi: AreaOfInterest
) {
  localStorage.setItem(AS_AOI, aoi.id.toString(10));
  defaultGet(
    dispatch,
    UserActionTypes.PATCH_SUCCESS,
    UserActionTypes.PATCH_FAILED,
    UserActionTypes.PATCH_USER,
    client().changeAoi,
    false,
    false,
    aoi.id
  );
}

export function acceptAgb(dispatch: Dispatch<UserActions>, newsletter = false) {
  defaultGet(
    dispatch,
    UserActionTypes.PATCH_SUCCESS,
    UserActionTypes.PATCH_FAILED,
    UserActionTypes.PATCH_USER,
    client().patchUser,
    false,
    false,
    { agbLevel: AGB_LEVEL, newsletter }
  );
}

export function registerPatch(
  dispatch: Dispatch<UserActions>,
  username: string,
  givenName: string,
  familyName: string,
  email: string,
  password: string,
  passwordRepeat: string,
  tel: string,
  newsletter: boolean
) {
  if (password !== passwordRepeat) {
    dispatch({
      type: UserActionTypes.REGISTER_FORM_INVALID,
      payload: "pwNotSame",
    });
    return;
  }
  if (password.length < 8) {
    dispatch({
      type: UserActionTypes.REGISTER_FORM_INVALID,
      payload: "pwNotValid",
    });
    return;
  }
  if (checkMail(email)) {
    client()
      .emailAvailable(email)
      .then(
        (response) => {
          if (response) {
            client()
              .telAvailable(tel)
              .then((responseTel) => {
                if (responseTel) {
                  defaultGet(
                    dispatch,
                    UserActionTypes.PATCH_SUCCESS,
                    UserActionTypes.PATCH_FAILED,
                    UserActionTypes.PATCH_USER,
                    client().registerPatch,
                    false,
                    false,
                    username,
                    givenName,
                    familyName,
                    email,
                    password,
                    tel,
                    newsletter
                  );
                } else {
                  dispatch({
                    type: UserActionTypes.REGISTER_FORM_INVALID,
                    payload: "telGiven",
                  });
                }
              });
          } else {
            dispatch({
              type: UserActionTypes.REGISTER_FORM_INVALID,
              payload: "mailGiven",
            });
          }
        },
        () => {
          dispatch({
            type: UserActionTypes.REGISTER_FORM_INVALID,
            payload: "mailGiven",
          });
        }
      );
  } else {
    dispatch({
      type: UserActionTypes.REGISTER_FORM_INVALID,
      payload: "mailInvalid",
    });
  }
}

export function login(
  dispatch: Dispatch<UserActions>,
  email: string,
  password: string
) {
  if (checkMail(email)) {
    defaultGet(
      dispatch,
      UserActionTypes.PATCH_SUCCESS,
      UserActionTypes.PATCH_FAILED,
      UserActionTypes.LOGIN,
      client().authorize,
      false,
      false,
      email,
      password
    );
  } else {
    dispatch({
      type: UserActionTypes.LOGIN_FORM_INVALID,
      payload: "mailInvalid",
    });
  }
}

export function confirmMail(dispatch: Dispatch<UserActions>, code: string) {
  defaultGet(
    dispatch,
    UserActionTypes.CONFIRM_MAIL_SUCCESS,
    UserActionTypes.CONFIRM_MAIL_FAILED,
    UserActionTypes.PATCH_USER,
    client().confirmMail,
    false,
    false,
    code
  );
}

export function confirmTel(dispatch: Dispatch<UserActions>, code: string) {
  defaultGet(
    dispatch,
    UserActionTypes.CONFIRM_TEL_SUCCESS,
    UserActionTypes.CONFIRM_TEL_FAILED,
    UserActionTypes.PATCH_USER,
    client().confirmTel,
    false,
    false,
    code
  );
}

export function pwForgot(dispatch: Dispatch<UserActions>, email: string) {
  defaultGet(
    dispatch,
    UserActionTypes.PW_FORGOT,
    UserActionTypes.PW_FORGOT_FAILED,
    UserActionTypes.PATCH_USER,
    client().pwForgot,
    false,
    false,
    email
  );
}

export function resendSMS(dispatch: Dispatch<UserActions>) {
  defaultGet(
    dispatch,
    UserActionTypes.PW_FORGOT,
    UserActionTypes.PW_FORGOT_FAILED,
    UserActionTypes.PATCH_USER,
    client().resendCode,
    false,
    false
  );
}

export function pwReset(
  dispatch: Dispatch<UserActions>,
  password: string,
  passwordRepeat: string,
  hash: string
) {
  if (password !== passwordRepeat) {
    dispatch({
      type: UserActionTypes.REGISTER_FORM_INVALID,
      payload: "pwNotSame",
    });
    return;
  }
  if (password.length < 8) {
    dispatch({
      type: UserActionTypes.REGISTER_FORM_INVALID,
      payload: "pwNotValid",
    });
    return;
  }
  defaultGet(
    dispatch,
    UserActionTypes.PATCH_SUCCESS,
    UserActionTypes.PATCH_FAILED,
    UserActionTypes.PATCH_USER,
    client().pwReset,
    false,
    false,
    password,
    hash
  );
}

export function applyInsider(
  dispatch: Dispatch<UserActions>,
  teamIds: IDType[]
) {
  defaultGet(
    dispatch,
    UserActionTypes.PATCH_SUCCESS,
    UserActionTypes.PATCH_FAILED,
    UserActionTypes.PATCH_USER,
    client().postApplyInsider,
    false,
    false,
    teamIds
  );
}

export function abortInsider(
  dispatch: Dispatch<UserActions>,
  userId: IDType,
  teamId: IDType
) {
  defaultGet(
    dispatch,
    UserActionTypes.PATCH_SUCCESS,
    UserActionTypes.PATCH_FAILED,
    UserActionTypes.PATCH_USER,
    client().abortInsider,
    false,
    false,
    userId,
    teamId
  );
}

export function applyInsiderSingle(
  dispatch: Dispatch<UserActions>,
  teamId: IDType
) {
  defaultGet(
    dispatch,
    UserActionTypes.PATCH_SUCCESS,
    UserActionTypes.PATCH_FAILED,
    UserActionTypes.PATCH_USER,
    client().applyInsider,
    false,
    false,
    teamId
  );
}

export function removeInsider(
  dispatch: Dispatch<UserActions>,
  userId: IDType,
  teamId: IDType
) {
  defaultGet(
    dispatch,
    UserActionTypes.DECLINE_SUCCESS,
    UserActionTypes.MANAGE_USER_FAILED,
    UserActionTypes.MANAGE_USER,
    client().postRemoveInsider,
    false,
    false,
    userId,
    teamId
  );
}

export function upgradeInsider(
  dispatch: Dispatch<UserActions>,
  userId: IDType,
  teamId: IDType
) {
  defaultGet(
    dispatch,
    UserActionTypes.MANAGE_USER_SUCCESS,
    UserActionTypes.MANAGE_USER_FAILED,
    UserActionTypes.MANAGE_USER,
    client().postUpgradeInsider,
    false,
    false,
    userId,
    teamId
  );
}

export function downgradeInsider(
  dispatch: Dispatch<UserActions>,
  userId: IDType,
  teamId: IDType
) {
  defaultGet(
    dispatch,
    UserActionTypes.MANAGE_USER_SUCCESS,
    UserActionTypes.MANAGE_USER_FAILED,
    UserActionTypes.MANAGE_USER,
    client().postDowngradeInsider,
    false,
    false,
    userId,
    teamId
  );
}

export function getInsiderByTeam(
  dispatch: Dispatch<UserActions>,
  teamId: IDType
) {
  defaultGet(
    dispatch,
    UserActionTypes.GET_INSIDERS_SUCCESS,
    UserActionTypes.FETCH_ERROR,
    UserActionTypes.GET_INSIDERS,
    client().getInsidersByTeam,
    false,
    false,
    teamId
  );
}

export function logout(dispatch: Dispatch<UserActions>) {
  client()
    .logout()
    .then(() => {
      dispatch({ type: UserActionTypes.LOGOUT });
    });
}

export function checkVersion(dispatch: Dispatch<UserActions>, version: string) {
  defaultGet(
    dispatch,
    UserActionTypes.CHECK_VERSION_SUCCESS,
    UserActionTypes.FETCH_ERROR,
    UserActionTypes.GET_VERSION,
    client().checkVersion,
    false,
    false,
    version
  );
}

export function deleteAccount(dispatch: Dispatch<UserActions>) {
  defaultGet(
    dispatch,
    UserActionTypes.DELETE_ME_SUCCESS,
    UserActionTypes.FETCH_ERROR,
    UserActionTypes.PATCH_USER,
    client().deleteAccount,
    false,
    false
  );
}

// reload
