import React, { useCallback, useContext, useState } from "react";
import { Modal, Navbar, NavbarToggler } from "reactstrap";
import { NavLink } from "react-router-dom";
import {
  faArrowRightToLine,
  faBars,
  faMobileAndroid,
  faTrophy,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ConnectedProps } from "react-redux";
import { translate } from "../../../config/translator";
import { ClavaContext } from "../../../config/contexts";
import Sidebar from "../Sidebar";
import Logo from "../Logo";
import { connector } from "./redux";
import { APP_STORE_URL } from "../../../config/constants";

const SidebarFadeTrans = {
  timeout: 250,
  baseClass: "sidebar",
};
const Header: React.FC<ConnectedProps<typeof connector>> = ({
  user,
  newInsiders,
}) => {
  const { l } = useContext(ClavaContext);
  const [toggleSidebar, setToggleSidebar] = useState(false);
  const onToggle = useCallback(() => {
    setToggleSidebar((t) => !t);
  }, []);
  const linkClasses = useCallback(
    ({ isActive, isPending }: { isActive: boolean; isPending: boolean }) => {
      if (isActive) return "active";
      if (isPending) return "pending";
      return "";
    },
    []
  );
  return (
    <>
      <div className="header">
        <NavLink to="/home" className="logo-container">
          <Logo onPress={undefined} />
        </NavLink>
        <Navbar className="hidden-xs">
          {/* <NavLink to="/home">
            <FontAwesomeIcon icon={faHouse} />
            {translate('home', l)}
          </NavLink>
          <NavLink to="/feed/news">
            <FontAwesomeIcon icon={faNewspaper} />
            {translate('news',  l)}
          </NavLink>
          */}
          <NavLink to="/home" className={linkClasses}>
            <FontAwesomeIcon icon={faTrophy} />
            <span>{translate("results", l)}</span>
          </NavLink>
          {/*  <NavLink to="/news" className={linkClasses}>
            <FontAwesomeIcon icon={faNewspaper} />
            <span>{translate("feed", l)}</span>
          </NavLink> */}
        </Navbar>
        <div className="hidden-xs load-app">
          <a href={APP_STORE_URL}>
            <FontAwesomeIcon icon={faMobileAndroid} />
            <span>{` ${translate("watchApp", l)}`}</span>
          </a>
        </div>
        <NavbarToggler onClick={onToggle}>
          <FontAwesomeIcon icon={toggleSidebar ? faArrowRightToLine : faBars} />
        </NavbarToggler>
      </div>
      <Modal
        isOpen={toggleSidebar}
        modalTransition={SidebarFadeTrans}
        backdrop={false}
        unmountOnClose={false}
      >
        <Sidebar />
      </Modal>
    </>
  );
};
// reload
export default connector(Header);
