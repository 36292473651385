import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Button, Col, FormGroup, Label, Row } from "reactstrap";
import { ConnectedProps } from "react-redux";
import { faAdd, faTrash, faUndo } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  LeagueCategoryEnum,
  LeagueRatingEnum,
  SportEnum,
  TeamListElement,
} from "../../../../../client/api";
import { translate } from "../../../../../config/translator";
import ClavaPicker from "../../../../components/Form/ClavaPicker";
import CheckboxInput from "../../CheckboxInput";
import TextInput from "../../TextInput";
import { ClavaContext } from "../../../../../config/contexts";
import { IDType } from "../../../../../config/types";
import { connector } from "./redux";
import TeamName from "../../../../components/Team/TeamName";
import Loading from "../../../../components/Loading";
import SearchInput from "../../SearchInput";
import ClavaSwitch, {
  yesNoItems,
} from "../../../../components/Form/ClavaSwitch";

type TeamRowProps = {
  team: TeamListElement;
  onClick: (id: IDType) => void;
  deleted: boolean;
};

const TeamRow: React.FC<TeamRowProps> = ({ team, onClick, deleted }) => {
  const onClickDelete = useCallback(() => {
    onClick(team.id);
  }, [onClick, team]);
  return (
    <Col
      xs={12}
      sm={6}
      className={`even-even-odd ${
        deleted ? "text-live text-decoration-line-through" : ""
      }`}
    >
      <Row>
        <Col xs={8} className="d-flex justify-content-center flex-column">
          <TeamName team={team} live={deleted} />
        </Col>
        <Col xs={4}>
          <Button color="transparent" onClick={onClickDelete}>
            <FontAwesomeIcon
              icon={deleted ? faUndo : faTrash}
              className="text-danger"
            />
          </Button>
        </Col>
      </Row>
    </Col>
  );
};

const EditCreateLeague: React.FC<ConnectedProps<typeof connector>> = ({
  teamsResult,
  searchTeam,
  selectedLeague,
  onSubmit,
  aois,
  statusSearch,
  teams,
  statusTeams,
  getTeams,
  status,
  addTeamsToLeague,
  removeTeamFromLeague,
}) => {
  const { l } = useContext(ClavaContext);
  const [textDE, setTextDE] = useState(selectedLeague?.name.textDE ?? "");
  const [textIT, setTextIT] = useState(selectedLeague?.name.textIT ?? "");
  const [textEN, setTextEN] = useState(selectedLeague?.name.textEN ?? "");
  const [hasPlayerStatistics, setHasPlayerStatistics] = useState(
    selectedLeague?.hasPlayerStatistics ?? true
  );
  const [hasTeamStatistics, setHasTeamStatistics] = useState(
    selectedLeague?.hasTeamStatistics ?? true
  );
  const [hasLineup, setHasLineup] = useState(selectedLeague?.hasLineup ?? true);
  const [hasManOfTheMatch, setHasManOfTheMatch] = useState(
    selectedLeague?.hasManOfTheMatch ?? true
  );
  const [hasFeed, setHasFeed] = useState(selectedLeague?.hasFeed ?? true);
  const [hasScorers, setHasScorers] = useState(
    selectedLeague?.hasScorers ?? true
  );
  const [hasSquad, setHasSquad] = useState(selectedLeague?.hasSquad ?? true);
  const [hasBetting, setHasBetting] = useState(
    selectedLeague?.hasBetting ?? true
  );
  const [amountPlayers, setAmountPlayers] = useState(
    selectedLeague?.amountPlayers ?? 11
  );
  const [category, setCategory] = useState<LeagueCategoryEnum>(
    selectedLeague?.category ?? LeagueCategoryEnum.LEAGUE
  );
  const [deletedTeams, setDeletedTeams] = useState<IDType[]>([]);
  const [teamQuery, setTeamQuery] = useState("");
  const [foundTeam, onFoundTeam] = useState<TeamListElement>();
  const [addedTeams, setAddedTeams] = useState<TeamListElement[]>([]);
  const addTeam = useCallback(() => {
    if (foundTeam) {
      setAddedTeams((at) => at.concat([foundTeam]));
    }
  }, [foundTeam]);
  const [matchSectionAmount, setMatchSectionAmount] = useState(
    selectedLeague?.matchSectionAmount ?? 2
  );
  const [matchSectionDurationMinutes, setMatchSectionDurationMinutes] =
    useState(selectedLeague?.matchSectionDurationMinutes ?? 45);
  const [
    matchSectionPauseDurationMinutes,
    setMatchSectionPauseDurationMinutes,
  ] = useState(selectedLeague?.matchSectionPauseDurationMinutes ?? 15);
  const [amountMatchDaysHalfSeason, setAmountMatchDaysHalfSeason, ,] = useState(
    selectedLeague?.amountMatchDaysHalfSeason ?? 12
  );
  const searchTimeout = useRef(0);
  const onSearchTeam = useCallback(
    (t: string) => {
      window.clearTimeout(searchTimeout.current);
      setTeamQuery(t);
      searchTimeout.current = window.setTimeout(() => {
        searchTeam(t);
      }, 300);
    },
    [searchTeam]
  );

  const teamsOfLeague = useMemo(() => {
    if (!teams) return undefined;
    return teams.response;
  }, [teams]);
  const selectedTeams = useMemo(() => {
    if (teamsOfLeague) return teamsOfLeague.concat(addedTeams);
    return addedTeams;
  }, [teamsOfLeague, addedTeams]);
  const message = useRef<string>();
  useEffect(() => {
    if (selectedLeague) {
      if (!teamsOfLeague && statusTeams !== "loading") {
        getTeams(selectedLeague.id);
      }
    }
  }, [statusTeams, selectedLeague, getTeams, teamsOfLeague]);
  const [tableRating, setTableRating] = useState<LeagueRatingEnum>(
    selectedLeague?.tableRating ?? LeagueRatingEnum.SOUTHTYROL
  );
  const [matchDays, setMatchDays] = useState(selectedLeague?.matchDays ?? 1);
  const [main, setMain] = useState<boolean>(selectedLeague?.main ?? true);
  const [areaOfInterestIds, setAreaOfInterestIds] = useState<IDType[]>(
    selectedLeague?.areasOfInterest?.map((a) => a.id) ?? []
  );
  const [sports, setSports] = useState(
    selectedLeague?.sports ?? SportEnum.SOCCER
  );

  const [year, setYear] = useState(selectedLeague?.year ?? 2023);
  const pickerItems = useMemo(
    () => [
      {
        key: LeagueCategoryEnum.LEAGUE,
        label: translate(LeagueCategoryEnum.LEAGUE, l),
        value: LeagueCategoryEnum.LEAGUE,
      },
      {
        key: LeagueCategoryEnum.CUP,
        label: translate(LeagueCategoryEnum.CUP, l),
        value: LeagueCategoryEnum.CUP,
      },
      {
        key: LeagueCategoryEnum.YOUTH,
        label: translate(LeagueCategoryEnum.YOUTH, l),
        value: LeagueCategoryEnum.YOUTH,
      },
      {
        key: LeagueCategoryEnum.VSS_YOUTH,
        label: translate(LeagueCategoryEnum.VSS_YOUTH, l),
        value: LeagueCategoryEnum.VSS_YOUTH,
      },
      {
        key: LeagueCategoryEnum.VSS_FREE_TIME,
        label: translate(LeagueCategoryEnum.VSS_FREE_TIME, l),
        value: LeagueCategoryEnum.VSS_FREE_TIME,
      },
      {
        key: LeagueCategoryEnum.VIENNA,
        label: translate(LeagueCategoryEnum.VIENNA, l),
        value: LeagueCategoryEnum.VIENNA,
      },
      {
        key: LeagueCategoryEnum.WOMEN,
        label: translate(LeagueCategoryEnum.WOMEN, l),
        value: LeagueCategoryEnum.WOMEN,
      },
    ],
    [l]
  );
  const onSubmitCont = useCallback(() => {
    if (areaOfInterestIds.length !== 0) {
      if (selectedLeague) {
        const reallyAdded = addedTeams.filter(
          (aT) => deletedTeams.indexOf(aT.id) === -1
        );
        if (reallyAdded.length !== 0) {
          addTeamsToLeague(
            selectedLeague.id,
            reallyAdded.map((aT) => aT.id)
          );
        }
        if (deletedTeams.length !== 0) {
          removeTeamFromLeague(selectedLeague.id, deletedTeams);
        }
      }
      onSubmit({
        name: {
          textEN,
          textIT,
          textDE,
        },
        year,
        category,
        main,
        tableRating,
        areaOfInterestIds,
        matchDays,
        sports,
        matchSectionPauseDurationMinutes,
        matchSectionAmount,
        matchSectionDurationMinutes,
      });
      setTimeout(() => {
        setAddedTeams([]);
        setDeletedTeams([]);
        message.current = "success";
        if (selectedLeague) getTeams(selectedLeague.id);
      }, 1000);
    }
  }, [
    addTeamsToLeague,
    addedTeams,
    areaOfInterestIds,
    category,
    deletedTeams,
    main,
    matchDays,
    onSubmit,
    removeTeamFromLeague,
    selectedLeague,
    tableRating,
    textDE,
    textEN,
    textIT,
    year,
  ]);
  const setTableRatingCont = useCallback((val: string) => {
    setTableRating(val as LeagueRatingEnum);
  }, []);
  const setLeagueCategoryCont = useCallback((val: string) => {
    setCategory(val as LeagueCategoryEnum);
  }, []);
  const onDeleteTeam = useCallback((id: IDType) => {
    setDeletedTeams((del) =>
      del.indexOf(id) === -1 ? del.concat([id]) : del.filter((d) => d !== id)
    );
  }, []);
  return (
    <>
      <Row>
        <Col>
          <TextInput
            value={textDE}
            onChange={setTextDE}
            name="nameDE"
            label="nameDE"
          />
        </Col>
        <Col>
          <TextInput
            value={textIT}
            onChange={setTextIT}
            name="nameIT"
            label="nameIT"
          />
        </Col>
        <Col>
          <TextInput
            value={textEN}
            onChange={setTextEN}
            name="nameEN"
            label="nameEN"
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <TextInput
            value={matchSectionAmount}
            onChange={setMatchSectionAmount}
            name="matchSectionAmount"
            label="matchSectionAmount"
          />
        </Col>
        <Col>
          <TextInput
            value={matchSectionDurationMinutes}
            onChange={setMatchSectionDurationMinutes}
            name="matchSectionDurationMinutes"
            label="matchSectionDurationMinutes"
          />
        </Col>
        <Col>
          <TextInput
            value={matchSectionPauseDurationMinutes}
            onChange={setMatchSectionPauseDurationMinutes}
            name="matchSectionPauseDurationMinutes"
            label="matchSectionPauseDurationMinutes"
          />
        </Col>
      </Row>
      <FormGroup>
        <Label>{translate("leagueCategory", l)}</Label>
        <ClavaPicker
          items={pickerItems}
          value={category}
          onValueChange={setLeagueCategoryCont}
        />
      </FormGroup>
      <FormGroup>
        <Label>{translate("leagueRating", l)}</Label>
        <ClavaPicker
          items={[
            {
              key: LeagueRatingEnum.SOUTHTYROL,
              label: translate("southtyrol", l),
              value: LeagueRatingEnum.SOUTHTYROL,
            },
            {
              key: LeagueRatingEnum.TRENTINO,
              label: translate("trentino", l),
              value: LeagueRatingEnum.TRENTINO,
            },
          ]}
          value={tableRating}
          onValueChange={setTableRatingCont}
        />
      </FormGroup>
      <Row>
        <Col>
          <TextInput
            label="matchDays"
            onChange={setMatchDays}
            value={matchDays}
            name="matchDays"
          />
        </Col>
        <Col>
          <TextInput
            label="amountMatchDaysHalfSeason"
            onChange={setAmountMatchDaysHalfSeason}
            value={amountMatchDaysHalfSeason}
            name="amountMatchDaysHalfSeason"
          />
        </Col>
        <Col>
          {!!selectedLeague && (
            <TextInput
              onChange={setAmountPlayers}
              name="amountPlayers"
              label="amountPlayers"
              value={amountPlayers}
            />
          )}
        </Col>
      </Row>
      <TextInput label="year" onChange={setYear} value={year} name="year" />
      <Row>
        <Col>
          <CheckboxInput
            label="mainLeague"
            onChange={setMain}
            value={main}
            name="mainLeague"
          />
        </Col>
        <Col>
          <ClavaPicker
            items={[
              {
                key: SportEnum.SOCCER,
                label: translate(SportEnum.SOCCER, l),
                value: SportEnum.SOCCER,
              },
              {
                key: SportEnum.HOCKEY,
                label: translate(SportEnum.HOCKEY, l),
                value: SportEnum.HOCKEY,
              },
            ]}
            value={sports}
            onValueChange={(sp) => {
              setSports(sp as SportEnum);
            }}
          />
        </Col>
      </Row>
      <Row>
        {aois.map((aoi) => (
          <Col key={`aoi-checkbox-${aoi.id}`}>
            <CheckboxInput
              label={aoi}
              onChange={(checked) => {
                setAreaOfInterestIds((aoiIds) =>
                  aoiIds
                    .filter((aoiID) => aoiID !== aoi.id)
                    .concat(checked ? [aoi.id] : [])
                );
              }}
              name={`aoi${aoi.id}`}
              value={areaOfInterestIds.indexOf(aoi.id) !== -1}
            />
          </Col>
        ))}
      </Row>
      {!!selectedLeague && (
        <>
          <Row>
            <Col>
              <Row>
                <Col>
                  <Label htmlFor="hasPlayerStatistics">
                    {translate("hasPlayerStatistics", l)}
                  </Label>
                </Col>
                <Col>
                  <ClavaSwitch
                    items={yesNoItems}
                    onChange={setHasPlayerStatistics}
                    value={hasPlayerStatistics}
                  />
                </Col>
              </Row>
            </Col>
            <Col>
              <Row>
                <Col>
                  <Label htmlFor="hasTeamStatistics">
                    {translate("hasTeamStatistics", l)}
                  </Label>
                </Col>
                <Col>
                  <ClavaSwitch
                    items={yesNoItems}
                    onChange={setHasTeamStatistics}
                    value={hasTeamStatistics}
                  />
                </Col>
              </Row>
            </Col>
            <Col>
              <Row>
                <Col>
                  <Label htmlFor="hasManOfTheMatch">
                    {translate("hasManOfTheMatch", l)}
                  </Label>
                </Col>
                <Col>
                  <ClavaSwitch
                    items={yesNoItems}
                    onChange={setHasManOfTheMatch}
                    value={hasManOfTheMatch}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col>
              <Row>
                <Col>
                  <Label htmlFor="hasLineup">{translate("hasLineup", l)}</Label>
                </Col>
                <Col>
                  <ClavaSwitch
                    items={yesNoItems}
                    onChange={setHasLineup}
                    value={hasLineup}
                  />
                </Col>
              </Row>
            </Col>
            <Col>
              <Row>
                <Col>
                  <Label htmlFor="hasFeed">{translate("hasFeed", l)}</Label>
                </Col>
                <Col>
                  <ClavaSwitch
                    items={yesNoItems}
                    onChange={setHasFeed}
                    value={hasFeed}
                  />
                </Col>
              </Row>
            </Col>
            <Col>
              <Row>
                <Col>
                  <Label htmlFor="hasScorers">
                    {translate("hasScorers", l)}
                  </Label>
                </Col>
                <Col>
                  <ClavaSwitch
                    items={yesNoItems}
                    onChange={setHasScorers}
                    value={hasScorers}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col>
              <Row>
                <Col>
                  <Label htmlFor="hasSquad">{translate("hasSquad", l)}</Label>
                </Col>
                <Col>
                  <ClavaSwitch
                    items={yesNoItems}
                    onChange={setHasSquad}
                    value={hasSquad}
                  />
                </Col>
              </Row>
            </Col>
            <Col>
              <Row>
                <Col>
                  <Label htmlFor="hasBetting">
                    {translate("hasBetting", l)}
                  </Label>
                </Col>
                <Col>
                  <ClavaSwitch
                    items={yesNoItems}
                    onChange={setHasBetting}
                    value={hasBetting}
                  />
                </Col>
              </Row>
            </Col>
            <Col />
          </Row>
        </>
      )}
      {!!selectedLeague && (
        <Row>
          <Col xs={12}>{translate("teams", l)}</Col>
          {statusTeams === "loading" ? (
            <Loading small />
          ) : (
            selectedTeams.map((team) => (
              <TeamRow
                deleted={deletedTeams.indexOf(team.id) !== -1}
                onClick={onDeleteTeam}
                key={`team-${team.id}`}
                team={team}
              />
            ))
          )}
        </Row>
      )}
      <Row>
        <Col xs={8} className="pt-3">
          <SearchInput
            onChange={onSearchTeam}
            searching={statusSearch === "loading"}
            onSelect={onFoundTeam}
            isFocused={!foundTeam && teamQuery.length > 2}
            selectedItem={foundTeam}
            name="searchTeam"
            value={teamQuery}
            items={teamsResult}
          />
        </Col>
        <Col xs={4} className="pt-2">
          <Button onClick={addTeam} disabled={!foundTeam}>
            {translate("addTeam", l)}
            <FontAwesomeIcon icon={faAdd} className="ms-2" />
          </Button>
        </Col>
      </Row>
      {message.current && (
        <Row>
          <Col xs={12}>
            <span
              className={
                message.current === "success" ? "text-success" : "text-danger"
              }
            >
              {translate(
                message.current === "success" ? "saveSuccess" : "saveFailed",
                l
              )}
            </span>
          </Col>
        </Row>
      )}
      <Button color="primary" onClick={onSubmitCont}>
        {translate("submit", l)}
      </Button>
    </>
  );
};

export default connector(EditCreateLeague);
// reload
