/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum ChanceTypeEnum {
    POST = 'POST',
    MISSED_PENALTY = 'MISSED_PENALTY',
    SAVE_PENALTY = 'SAVE_PENALTY',
    DISALLOWED = 'DISALLOWED',
}
