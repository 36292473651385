import React, { useCallback, useMemo } from "react";
import { ConnectedProps } from "react-redux";
import AoiLang from "./AoiLang";
import { AreaOfInterest, Language } from "../../../client/api";
import { LanguageISO } from "../../../config/types";
import { connector } from "./redux";
import Logo from "../../components/Logo";

type FirstOpenProps = ConnectedProps<typeof connector> & {
  language: LanguageISO;
  finalCallback: () => void;
};

const FirstOpen: React.FC<FirstOpenProps> = ({
  language,
  finalCallback,
  setAreaOfInterest,
  aoi,
  setLanguageObject,
  languageObject,
}) => {
  const realLang: LanguageISO = useMemo(
    () => (languageObject ? languageObject.locale : language),
    [language, languageObject]
  );
  const callbackAoi = useCallback(
    (area: AreaOfInterest, lang: Language) => {
      setAreaOfInterest(area);
      setLanguageObject(lang);
      finalCallback();
    },
    [finalCallback, setAreaOfInterest, setLanguageObject]
  );
  return (
    <div className="main first-open align-content-center justify-content-center ">
      <Logo vertical />
      <AoiLang callback={callbackAoi} language={realLang} />
    </div>
  );
};

export default connector(FirstOpen);
