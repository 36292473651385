import React, { useCallback, useContext } from "react";
import { Button, Col, Row } from "reactstrap";
import { ConnectedProps } from "react-redux";
import { faChevronDown, faChevronUp } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { League, MoveEnum } from "../../../../../client/api";
import { showTranslated } from "../../../../../config/translator";
import { ClavaContext } from "../../../../../config/contexts";
import { connector } from "./redux";

const ReorderLeagueElem: React.FC<{
  league: League;
  onMove: (league: League, direction: MoveEnum) => void;
}> = ({ league, onMove }) => {
  const { l } = useContext(ClavaContext);
  const onMoveUp = useCallback(() => {
    onMove(league, MoveEnum.UP);
  }, [league, onMove]);
  const onMoveDown = useCallback(() => {
    onMove(league, MoveEnum.DOWN);
  }, [league, onMove]);
  return (
    <Row className="reorder-league">
      <Col>{showTranslated(league.name, l)}</Col>
      <Col>
        <Button color="transparent" onClick={onMoveUp}>
          <FontAwesomeIcon icon={faChevronUp} />
        </Button>
        <Button color="transparent" onClick={onMoveDown}>
          <FontAwesomeIcon icon={faChevronDown} />
        </Button>
      </Col>
    </Row>
  );
};

const ReorderLeague: React.FC<ConnectedProps<typeof connector>> = ({
  leagues,
  onMove,
  sports,
}) => {
  const { aoi } = useContext(ClavaContext);
  const onMoveLeague = useCallback(
    (league: League, direction: MoveEnum) => {
      onMove(league.id, direction, aoi, sports);
    },
    [aoi, onMove]
  );
  return (
    <Row>
      <Col xs={12}>
        {leagues.map((l) => (
          <ReorderLeagueElem
            league={l}
            onMove={onMoveLeague}
            key={`reorder-${l.id}`}
          />
        ))}
      </Col>
    </Row>
  );
};

export default connector(ReorderLeague);
// reload
