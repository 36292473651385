import { ConnectedProps } from "react-redux";
import React, {
  ChangeEvent,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { Button, Col, Input, Row } from "reactstrap";
import { ClavaContext } from "../../../../config/contexts";
import { showTranslated, translate } from "../../../../config/translator";
import { connector } from "./redux";
import TextInput from "../TextInput";
import {
  CACHE_TASK,
  SQUAD_TASK,
  STATISTICS_TASK,
} from "../../../../store/actions/types";
import Loading from "../../../components/Loading";
import CheckboxInput from "../CheckboxInput";
import { AS_ADMIN_KEY } from "../../../../config/constants";
import client from "../../../../client";

const AdminpanelAdministration: React.FC<ConnectedProps<typeof connector>> = ({
  createTask,
  status,
  error,
  getAois,
  aois,
}) => {
  const { l, user } = useContext(ClavaContext);
  const [key, setKey] = useState(localStorage.getItem(AS_ADMIN_KEY) ?? "");
  const [saveKey, setSaveKey] = useState(false);
  const [loading, setLoading] = useState<boolean | string>(false);
  const [selectedAoi, setSelectedAoi] = useState<number>(
    user.areaOfInterest.id
  );
  const [selectedMatchday, setSelectedMatchday] = useState<number>();
  const taskSend = useRef(false);
  const onClearCache = useCallback(() => {
    taskSend.current = true;
    if (saveKey) localStorage.setItem(AS_ADMIN_KEY, key);
    createTask(key, CACHE_TASK);
  }, [createTask, key, saveKey]);
  const onRecalculateSquad = useCallback(() => {
    taskSend.current = true;
    createTask(key, SQUAD_TASK);
    if (saveKey) localStorage.setItem(AS_ADMIN_KEY, key);
  }, [createTask, key, saveKey]);
  const onRecalculateStat = useCallback(() => {
    taskSend.current = true;
    createTask(key, STATISTICS_TASK);
    if (saveKey) localStorage.setItem(AS_ADMIN_KEY, key);
  }, [createTask, key, saveKey]);
  const onSetAoi = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setSelectedAoi(parseInt(e.target.value, 10));
  }, []);
  const onSetMatchday = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const n = parseInt(e.target.value, 10);
    setSelectedMatchday(Number.isNaN(n) ? undefined : n);
  }, []);
  const onGenerateReport = useCallback(() => {
    setLoading(true);
    client()
      .generateReport(selectedAoi, selectedMatchday)
      .then(() => {
        setLoading("done");
      });
  }, [selectedAoi, selectedMatchday]);
  useEffect(() => {
    getAois();
  }, [getAois]);
  return (
    <div>
      <fieldset className="form open">
        <TextInput label="key" onChange={setKey} value={key} name="key" />
        <CheckboxInput
          label="saveKey"
          onChange={setSaveKey}
          name="saveKey"
          value={saveKey}
        />
        {taskSend.current && (
          <>
            {status === "idle" && (
              <span className="text-success">
                {translate("taskCreateSuccess", l)}
              </span>
            )}
            {status === "loading" && <Loading small />}
            {status === "failed" && (
              <span className="text-danger">
                {translate("taskCreateFailed", l)}
                <br />
                {error}
              </span>
            )}
          </>
        )}

        <Row className="mt-2 text-center">
          <Col xs={12}>
            <Button
              color="primary"
              disabled={key === ""}
              onClick={onClearCache}
            >
              <span>{translate("clearCache", l)}</span>
            </Button>
          </Col>
        </Row>
        <Row className="mt-2 text-center">
          <Col xs={12}>
            <Button
              color="primary"
              disabled={key === ""}
              onClick={onRecalculateSquad}
            >
              <span>{translate("recalculateSqua", l)}</span>
            </Button>
          </Col>
        </Row>
        <Row className="mt-2 text-center">
          <Col xs={12}>
            <Button
              color="primary"
              disabled={key === ""}
              onClick={onRecalculateStat}
            >
              <span>{translate("recalculateStat", l)}</span>
            </Button>
          </Col>
        </Row>
        <Row className="mt-2">
          <Col xs={12}>
            <h3>Playerscore</h3>
          </Col>
          {loading === "done" && (
            <Col xs={12}>
              <span>
                {translate("reportGenerating", l, {
                  "[mail]": `${user.email}`,
                })}
              </span>
            </Col>
          )}
        </Row>
        {loading !== true ? (
          <Row className="mt-2 text-center">
            <Col xs={4}>
              <Input type="select" value={selectedAoi} onChange={onSetAoi}>
                {aois.map((aoi) => (
                  <option key={`asoi-${aoi.id}`} value={aoi.id}>
                    {showTranslated(aoi.name, l)}
                  </option>
                ))}
              </Input>
            </Col>
            <Col xs={4}>
              <Input
                type="number"
                placeholder={translate("matchDay", l)}
                defaultValue={selectedMatchday}
                onChange={onSetMatchday}
              />
            </Col>
            <Col xs={4}>
              <Button color="primary" onClick={onGenerateReport}>
                <span>{translate("generateReport", l)}</span>
              </Button>
            </Col>
          </Row>
        ) : (
          <Row className="mt-2 text-center">
            {loading === true ? <Loading small /> : null}
          </Row>
        )}
      </fieldset>
    </div>
  );
};

export default connector(AdminpanelAdministration);
// rel
