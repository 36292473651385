import { EnhancedStore } from "@reduxjs/toolkit";
import * as Sentry from "@sentry/react";
import axios, { AxiosProgressEvent, AxiosRequestConfig } from "axios";
import { Favorite, IDType, LanguageISO } from "../config/types";
import {
  AdCreate,
  AdministrationService,
  AdPatch,
  AdPositionEnum,
  AdService,
  ApiError,
  AppService,
  AreaOfInterest,
  AreaOfInterestService,
  AuthenticationService,
  AuthResponse,
  BadgeCreate,
  BadgePatch,
  BadgeService,
  BadgeTypeEnum,
  Blog,
  BlogCreate,
  BlogService,
  BulletinService,
  CancelablePromise,
  EventService,
  ExportService,
  ExternalVideo,
  ExternalVideoCreateRaw,
  ExternalVideoService,
  FeedService,
  File,
  GoalDistributionService,
  ImageTypeEnum,
  ImportService,
  Language,
  LanguageLocaleEnum,
  LanguageService,
  League,
  LeagueCreate,
  LeagueMatch,
  LeaguePatch,
  LeagueService,
  LineupCreate,
  LineupService,
  LocationCreate,
  LocationService,
  ManOfTheMatchService,
  ManOfTheMatchVote,
  Match,
  MatchBetService,
  MatchCreate,
  MatchDayService,
  MatchImport,
  MatchListElement,
  MatchLocationEnum,
  MatchPatch,
  MatchService,
  MoveEnum,
  OpenAPI,
  OutService,
  PlayerCreate,
  PlayerPatch,
  PlayerService,
  ScopeEnum,
  SearchRequest,
  SearchResult,
  SearchService,
  SearchTypeEnum,
  SponsorCreate,
  SponsorPatch,
  SponsorService,
  SportEnum,
  SquadService,
  StandingService,
  StatisticKeyEnum,
  StatisticsService,
  Team,
  TeamListElement,
  TeamPatch,
  TeamService,
  Transfer,
  TransferService,
  User,
  UserBadgeCreateDelete,
  UserCreate,
  UserMediaService,
  UserPatch,
  UserService,
} from "./api";
import { errorLogging, formatDate } from "../config/utils";
import {
  AGB_LEVEL,
  AMOUNT_MATCHDAYS,
  PROD_ENDPOINT,
} from "../config/constants";
import { ClavaFile } from "../views/screens/Adminpanel/FileInput";
import { addLog } from "../store/middleware/logger";
import { MatchFilterType } from "../views/screens/Adminpanel/AdminMatch/types";

class Client {
  public static token: string | undefined = undefined;

  private static JWT_STORAGE = "jwt_token";

  private static JWT_REFRESH_STORAGE = "jwt_refresh_token";

  private static refreshToken: string | undefined = undefined;

  private static __instance: Client | null = null;

  private endpoint = `https://${PROD_ENDPOINT}`;

  private constructor(endpoint: string) {
    this.endpoint = `https://${endpoint}`;
    OpenAPI.TOKEN = Client.getToken;
    OpenAPI.BASE = this.endpoint;
  }

  static getInstance(endpoint?: string): Client {
    if (Client.__instance === null) {
      if (!endpoint) endpoint = PROD_ENDPOINT;
      errorLogging(`first client call with ${endpoint}`);
      Client.__instance = new Client(endpoint as string);
    }
    return Client.__instance;
  }

  public static getToken(): Promise<string> {
    return new Promise<string>((resolve) => {
      if (Client.token) resolve(Client.token);
      const val = window.localStorage.getItem(Client.JWT_STORAGE);
      if (val) {
        Client.token = val;
        resolve(val);
      }
      resolve("");
    });
  }

  public static getRefreshToken(): Promise<string> {
    return new Promise<string>((resolve) => {
      if (Client.refreshToken) resolve(Client.refreshToken);
      const val = window.localStorage.getItem(Client.JWT_REFRESH_STORAGE);
      if (val) {
        Client.refreshToken = val;
        resolve(val);
      }
      resolve("");
    });
  }

  public static setToken(response: AuthResponse) {
    Client.token = response.access_token;
    Client.refreshToken = response.refresh_token;
    window.localStorage.setItem(Client.JWT_STORAGE, response.access_token);
    window.localStorage.setItem(
      Client.JWT_REFRESH_STORAGE,
      response.refresh_token
    );
  }

  public static async removeToken() {
    Client.token = undefined;
    Client.refreshToken = undefined;
    window.localStorage.removeItem(Client.JWT_STORAGE);
    window.localStorage.removeItem(Client.JWT_REFRESH_STORAGE);
  }

  setEndpoint(endpoint: string) {
    this.endpoint = `https://${endpoint}`;
    OpenAPI.BASE = this.endpoint;
  }

  getEndpoint(): string {
    return this.endpoint.replace("https://", "");
  }

  public imgToken() {
    return Client.token;
  }

  public setLang(lang: LanguageISO) {
    OpenAPI.HEADERS = { "User-Lang": lang };
  }

  public changeLanguage(languageId: IDType) {
    return UserService.patchUserUserPatch({ languageId });
  }

  public changeUsername(username: string) {
    return UserService.patchUserUserPatch({ username });
  }

  public changePassword(password: string): CancelablePromise<User> {
    return new CancelablePromise<User>((resolve, reject) => {
      UserService.changePasswordUserPasswordChangePost({ password }).then(
        (response) => {
          Client.setToken(response);
          resolve(response.user);
        },
        reject
      );
    });
  }

  public telAvailable(tel: string) {
    return UserService.telAvailableUserTelAvailableTelGet(tel);
  }

  public patchUser(user: UserPatch) {
    return UserService.patchUserUserPatch(user);
  }

  addFavorite(favorite: Favorite) {
    switch (favorite.type) {
      case "match":
        return UserService.favoriteMatchUserFavoriteMatchMatchIdPut(
          favorite.id
        );
      case "team":
        return UserService.favoriteTeamUserFavoriteTeamTeamIdPut(favorite.id);
      case "league":
        return UserService.favoriteLeagueUserFavoriteLeagueLeagueIdPut(
          favorite.id
        );

      default:
        return new CancelablePromise<User>((resolve, reject) => {
          reject("no_type_specified");
        });
    }
  }

  removeFavorite(favorite: Favorite) {
    switch (favorite.type) {
      case "match":
        return UserService.favoriteMatchUserUnfavoriteMatchMatchIdPut(
          favorite.id
        );
      case "team":
        return UserService.unfavoriteTeamUserUnfavoriteTeamTeamIdPut(
          favorite.id
        );
      case "league":
        return UserService.unfavoriteLeagueUserUnfavoriteLeagueLeagueIdPut(
          favorite.id
        );
      default:
        return new CancelablePromise<User>((resolve, reject) => {
          reject("no_type_specified");
        });
    }
  }

  public registerPatch(
    username: string,
    givenName: string,
    familyName: string,
    email: string,
    password: string,
    tel: string,
    newsletter: boolean
  ) {
    return new CancelablePromise((resolve, reject) => {
      UserService.registerUserUserRegisterPost({
        familyName,
        email,
        password,
        givenName,
        tel,
        agbLevel: AGB_LEVEL,
        newsletter,
      }).then((response) => {
        Client.setToken(response);
        UserService.patchUserUserPatch({ username }).then(
          (response2) => {
            resolve(response2);
          },
          () => {
            resolve(response.user);
          }
        );
      }, reject);
    });
  }

  public logout() {
    return Client.removeToken();
  }

  public deleteAccount() {
    return new CancelablePromise((resolve, reject) => {
      UserService.deleteUserUserDelete().then((response) => {
        Client.removeToken().then(() => {
          resolve(response);
        });
      }, reject);
    });
  }

  me() {
    return new CancelablePromise<User>((resolve, reject) => {
      UserService.getUserMeUserMeGet().then((user) => {
        if (user) {
          resolve(user);
        } else {
          // This is a very rare error and can only happen in DEV I think
          const error: ApiError = {
            body: "Unauthorized",
            name: "ApiError",
            statusText: "Unauthorized",
            url: "/user/me",
            message: "Unauthorized",
            status: 401,
            request: { method: "GET", url: "/user/me" },
          };
          reject(error);
        }
      }, reject);
    });
  }

  public loggedIn() {
    return typeof OpenAPI.TOKEN !== "undefined";
  }

  public authorize(email: string, password: string): CancelablePromise<User> {
    return new CancelablePromise((resolve, reject) => {
      AuthenticationService.secureLoginWithEmailPasswordAuthSecureLoginPost({
        email,
        password,
      }).then((response) => {
        Client.setToken(response);
        resolve(response.user);
      }, reject);
    });
  }

  pwForgot(email: string) {
    return UserService.forgotPasswordUserPasswordForgotPost(email);
  }

  pwReset(password: string, hash: string) {
    return new CancelablePromise((resolve, reject) => {
      UserService.resetPasswordUserPasswordResetPost({ password, hash }).then(
        (response) => {
          Client.setToken(response);
          resolve(response.user);
        },
        reject
      );
    });
  }

  refreshToken(): Promise<User> {
    return new Promise((resolve, reject) => {
      OpenAPI.TOKEN = Client.getRefreshToken;
      AuthenticationService.refreshAuthRefreshPost().then(
        (response) => {
          Client.setToken(response);
          OpenAPI.TOKEN = Client.getToken;
          resolve(response.user);
        },
        (error) => {
          OpenAPI.TOKEN = Client.getToken;
          reject(error);
        }
      );
    });
  }

  register(body: UserCreate): CancelablePromise<User> {
    return new CancelablePromise<User>((resolve, reject) => {
      Client.removeToken().then(() => {
        UserService.createUserUserPost(body).then((response) => {
          Client.setToken(response);
          resolve(response.user);
        }, reject);
      });
    });
  }

  confirmMail(code: string) {
    return new CancelablePromise<User>((resolve, reject) => {
      UserService.confirmMailUserEmailConfirmPost(code).then((auth) => {
        Client.setToken(auth);
        resolve(auth.user);
      }, reject);
    });
  }

  confirmTel(code: string) {
    return new CancelablePromise<User>((resolve, reject) => {
      UserService.confirmTelUserTelConfirmPost(code).then((auth) => {
        Client.setToken(auth);
        resolve(auth.user);
      }, reject);
    });
  }

  usernameAvailable(username: string) {
    return UserService.usernameAvailableUserUsernameAvailableUsernameGet(
      username
    );
  }

  emailAvailable(email: string) {
    return UserService.emailAvailableUserEmailAvailableEmailGet(email);
  }

  public getSquad(teamId: IDType) {
    return SquadService.getSquadSquadTeamIdGet(teamId);
  }

  public getPLayerPositions() {
    return PlayerService.getPlayerPositionsPlayerPlayerPositionGet();
  }

  public changeAoi(areaOfInterestId: IDType) {
    return UserService.patchUserUserPatch({ areaOfInterestId });
  }

  getLeaguesOfAoi(id: IDType, sports: SportEnum): CancelablePromise<League[]> {
    return LeagueService.getLeaguesLeagueGet(id, sports);
  }

  moveLeague(
    id: IDType,
    direction: MoveEnum,
    aoi: IDType,
    sports: SportEnum
  ): CancelablePromise<League[]> {
    return new CancelablePromise<League[]>((resolve, reject) => {
      LeagueService.moveLeagueLeagueMovePost(id, direction, aoi).then(() => {
        client()
          .getLeaguesOfAoi(aoi, sports)
          .then((leagues) => {
            resolve(leagues);
          }, reject);
      }, reject);
    });
  }

  getLeague(id: IDType): CancelablePromise<League> {
    return LeagueService.getLeagueByIdLeagueLeagueIdGet(id);
  }

  getTeamsOfLeague(id: IDType): CancelablePromise<TeamListElement[]> {
    return TeamService.getTeamsTeamGet(id);
  }

  getTeam(id: IDType): CancelablePromise<Team> {
    return TeamService.getTeamByIdTeamTeamIdGet(id);
  }

  patchTeam(id: IDType, body: TeamPatch) {
    return TeamService.patchTeamTeamTeamIdPatch(id, body);
  }

  addSponsor(teamId: IDType, body: SponsorCreate) {
    return SponsorService.createSponsorSponsorTeamTeamIdPost(teamId, body);
  }

  patchSponsor(teamId: IDType, body: SponsorPatch) {
    return SponsorService.patchSponsorSponsorSponsorIdPut(teamId, body);
  }

  removeSponsor(sponsorId: IDType) {
    return SponsorService.deleteSponsorSponsorSponsorIdDelete(sponsorId);
  }

  getLanguages(): CancelablePromise<Language[]> {
    return LanguageService.getLanguagesLanguageGet();
  }

  getLanguage(id: IDType): CancelablePromise<Language> {
    return LanguageService.getLanguageLanguageLanguageIdGet(id);
  }

  getAois(): CancelablePromise<AreaOfInterest[]> {
    return AreaOfInterestService.getAreaOfInterestsAreaOfInterestGet();
  }

  getAoi(id: IDType, store: EnhancedStore) {
    return new CancelablePromise((resolve, reject, onCancel) => {
      if (store.getState().aois.value.length) {
        this.filterAoi(store.getState().aois.value, id, resolve, reject);
      } else {
        AreaOfInterestService.getAreaOfInterestsAreaOfInterestGet().then(
          (aois) => {
            if (!onCancel.isCancelled) {
              this.filterAoi(aois, id, resolve, reject);
            }
          },
          reject
        );
      }
    });
  }

  getMatchDaysToday(
    amountDays: number,
    leagueID: IDType
  ): CancelablePromise<string[]> {
    return MatchDayService.getMatchDatesTodayMatchDayTodayAmountDaysGet(
      amountDays,
      leagueID
    );
  }

  getMatchDays(
    date: Date,
    options:
      | { aoi: IDType; type: "today" | "bigger" | "smaller" | "month" }
      | { league: IDType; type: "month" | "today" | "bigger" | "smaller" },
    sports: SportEnum
  ): CancelablePromise<string[]> {
    const dateString = formatDate(
      date,
      LanguageLocaleEnum.DE,
      true,
      false,
      true
    );
    switch (options.type) {
      case "month":
        return MatchDayService.getMatchDatesInMonthMatchDayMonthGet(
          dateString,
          "aoi" in options ? options.aoi : undefined,
          "aoi" in options ? undefined : options.league,
          sports
        );
      case "today":
        return MatchDayService.getMatchDatesTodayMatchDayTodayAmountDaysGet(
          AMOUNT_MATCHDAYS,
          "aoi" in options ? options.aoi : undefined,
          "aoi" in options ? undefined : options.league,
          sports
        );
      case "bigger":
        return MatchDayService.getMatchDatesBiggerMatchDayBiggerAmountDaysGet(
          AMOUNT_MATCHDAYS,
          dateString,
          "aoi" in options ? options.aoi : undefined,
          "aoi" in options ? undefined : options.league,
          sports
        );
      case "smaller":
        return MatchDayService.getMatchDatesSmallerMatchDaySmallerAmountDaysGet(
          AMOUNT_MATCHDAYS,
          dateString,
          "aoi" in options ? options.aoi : undefined,
          "aoi" in options ? undefined : options.league,
          sports
        );
      default:
        return new CancelablePromise<string[]>((resolve) => {
          Sentry.captureException(new Error("Invalid Matchday Type"));
          resolve([]);
        });
    }
  }

  getLeagueMatchesOfDay(
    date: Date,
    aoi: IDType,
    sports: SportEnum
  ): CancelablePromise<LeagueMatch[]> {
    return MatchService.getLeagueMatchByDateLeagueMatchesDateGet(
      formatDate(date, LanguageLocaleEnum.DE, true, false, true),
      aoi,
      sports
    );
  }

  getLeagueMatchesOfDayLeague(
    date: Date,
    leagueID: IDType,
    sports: SportEnum
  ): CancelablePromise<MatchListElement[]> {
    return MatchService.getMatchesByLeagueAndDateMatchLeagueLeagueIdDateMatchDateGet(
      leagueID,
      formatDate(date, LanguageLocaleEnum.DE, true, false, true)
    );
  }

  getLeagueMatchesOfMatchDayLeague(
    day: number,
    leagueID: IDType
  ): CancelablePromise<MatchListElement[]> {
    return MatchService.getMatchesByLeagueAndMatchDayMatchLeagueLeagueIdMatchDayMatchDayGet(
      leagueID,
      day
    );
  }

  getTable(leagueID: IDType) {
    return StandingService.getStandingByLeagueStandingLeagueAllLeagueIdGet(
      leagueID
    );
  }

  getMatch(id: IDType) {
    return MatchService.getMatchMatchMatchIdGet(id);
  }

  getMatchBet(id: IDType) {
    return MatchBetService.getMatchBetVotingByMatchMatchBetMatchIdGet(id);
  }

  putMatchBet(matchId: IDType, bet: MatchLocationEnum) {
    return MatchBetService.voteMatchMatchBetPut({ bet, matchId });
  }

  getPlayerInFocus(matchId: IDType, team1Id: IDType, team2Id: IDType) {
    return new CancelablePromise((resolve, reject) => {
      ManOfTheMatchService.getFocusedPlayersManOfTheMatchFocusMatchIdGet(
        matchId,
        team1Id,
        3,
        10
      ).then((team1) => {
        ManOfTheMatchService.getFocusedPlayersManOfTheMatchFocusMatchIdGet(
          matchId,
          team2Id,
          3,
          10
        ).then((team2) => {
          resolve({ team1, team2 });
        }, reject);
      }, reject);
    });
  }

  getGoalDistribution(matchId: IDType) {
    return GoalDistributionService.getGoalDistributionByMatchGoalDistributionMatchMatchIdGet(
      matchId
    );
  }

  getMatchTableAll(
    leagueID: IDType,
    type: MatchLocationEnum = MatchLocationEnum.ALL
  ) {
    return StandingService.getStandingByLeagueStandingLeagueLeagueIdGet(
      leagueID,
      type
    );
  }

  getMatchesOfTeam(id: IDType, limit: number, playedOnly: boolean) {
    return MatchService.getMatchesByTeamMatchTeamTeamIdGet(
      id,
      limit,
      playedOnly
    );
  }

  getPlayersOfTeam(id: IDType) {
    return PlayerService.getPlayersByTeamPlayerTeamTeamIdGet(id);
  }

  getPlayer(id: IDType) {
    return PlayerService.getPlayerPlayerPlayerIdGet(id);
  }

  patchPlayer(id: IDType, playerPatch: PlayerPatch) {
    return PlayerService.patchPlayerPlayerPlayerIdPatch(id, playerPatch);
  }

  addPlayer(playerPost: PlayerCreate) {
    return PlayerService.createPlayerPlayerPost(playerPost);
  }

  removePlayer(id: IDType) {
    return PlayerService.deletePlayerPlayerPlayerIdDelete(id);
  }

  getLeagueTeamStatistics(id: IDType) {
    return StatisticsService.getTeamStatisticsStatisticsTeamLeagueLeagueIdGet(
      id
    );
  }

  getLeaguePlayerStatistics(id: IDType) {
    return StatisticsService.getPlayerStatisticsLeagueStatisticsPlayerLeagueLeagueIdGet(
      id
    );
  }

  getDetailLeagueTeamStatistics(id: IDType, key: StatisticKeyEnum) {
    return StatisticsService.getTeamStatisticsDetailStatisticsTeamLeagueLeagueIdDetailStatisticKeyGet(
      id,
      key
    );
  }

  getTeamStatistics(id: IDType) {
    return StatisticsService.getSquadStatisticStatisticsSquadTeamTeamIdGet(id);
  }

  getTeamStatisticsDetail(id: IDType, stat: StatisticKeyEnum) {
    return StatisticsService.getSquadStatisticsDetailStatisticsSquadTeamTeamIdDetailStatisticKeyGet(
      id,
      stat
    );
  }

  getDetailLeaguePlayerStatistics(id: IDType, key: StatisticKeyEnum) {
    return StatisticsService.getPlayerStatisticsLeagueDetailStatisticsPlayerLeagueLeagueIdDetailStatisticKeyGet(
      id,
      key,
      20
    );
  }

  search(q: string, length = 5, offset = 0) {
    return SearchService.searchSearchQueryGet(q, length, offset);
  }

  searchExt(
    query: string,
    length: number,
    offset: number,
    type: "team" | "league" | "player"
  ): CancelablePromise<SearchResult> {
    switch (type) {
      case "player":
        return new CancelablePromise<SearchResult>((resolve, reject) => {
          SearchService.searchPlayerSearchPlayerQueryGet(
            query,
            length,
            offset
          ).then((players) => {
            resolve({ query, players, leagues: [], teams: [] });
          }, reject);
        });
      case "league":
        return new CancelablePromise<SearchResult>((resolve, reject) => {
          SearchService.searchLeagueSearchLeagueQueryGet(
            query,
            length,
            offset
          ).then((leagues) => {
            resolve({ query, players: [], leagues, teams: [] });
          }, reject);
        });
      case "team":
        return new CancelablePromise<SearchResult>((resolve, reject) => {
          SearchService.searchTeamSearchTeamQueryGet(
            query,
            length,
            offset
          ).then((teams) => {
            resolve({ query, players: [], leagues: [], teams });
          }, reject);
        });
      default:
        return SearchService.searchSearchQueryGet(query, length, offset);
    }
  }

  searchTeamAutocomplete(q: string) {
    return SearchService.searchTeamSearchTeamQueryGet(q, 10, 0);
  }

  getEvents(matchID: IDType) {
    return EventService.getEventsEventGet(matchID);
  }

  postCardEvent(
    matchID: IDType,
    minute: number,
    teamId: IDType,
    playerId: IDType,
    cardTypeId: IDType
  ) {
    return EventService.addCardEventEventCardMatchIdPost(matchID, {
      cardTypeId,
      minute,
      teamId,
      playerId,
    });
  }

  postChanceEvent(
    matchID: IDType,
    minute: number,
    teamId: IDType,
    chanceTypeId: IDType,
    playerId?: IDType
  ) {
    return EventService.addChanceEventEventChanceMatchIdPost(matchID, {
      minute,
      teamId,
      playerId,
      chanceTypeId,
    });
  }

  postChangeEvent(
    matchID: IDType,
    minute: number,
    teamId: IDType,
    playerOutId: IDType,
    injured: boolean,
    playerInId: IDType
  ) {
    return EventService.addChangeEventEventChangeMatchIdPost(matchID, {
      minute,
      teamId,
      playerId: playerOutId,
      injured,
      playerInId,
    });
  }

  postGoalEvent(
    matchID: IDType,
    minute: number,
    teamId: IDType,
    goal1: number,
    goal2: number,
    playerId?: IDType,
    assistId?: IDType,
    goalTypeId?: IDType,
    ignoreChecks?: boolean
  ) {
    return EventService.addGoalEventEventGoalMatchIdPost(
      matchID,
      {
        minute,
        teamId,
        playerId,
        assistId,
        goal1,
        goal2,
        goalTypeId,
      },
      ignoreChecks
    );
  }

  patchCardEvent(
    eventId: IDType,
    minute?: number,
    playerId?: IDType,
    cardTypeId?: IDType
  ) {
    return EventService.patchCardEventEventCardEventIdPatch(eventId, {
      cardTypeId,
      minute,
      playerId,
    });
  }

  patchChanceEvent(
    eventId: IDType,
    minute?: number,
    chanceTypeId?: IDType,
    playerId?: IDType
  ) {
    return EventService.patchChanceEventEventChanceEventIdPatch(eventId, {
      minute,
      playerId,
      chanceTypeId,
    });
  }

  patchChangeEvent(
    eventId: IDType,
    minute?: number,
    playerOutId?: IDType,
    injured?: boolean,
    playerInId?: IDType
  ) {
    return EventService.patchChangeEventEventChangeEventIdPatch(eventId, {
      minute,
      playerId: playerOutId,
      injured,
      playerInId,
    });
  }

  patchGoalEvent(
    eventId: IDType,
    minute?: number,
    playerId?: IDType,
    assistId?: IDType,
    goalTypeId?: IDType
  ) {
    return EventService.patchGoalEventEventGoalEventIdPatch(eventId, {
      minute,
      playerId,
      assistId,
      goalTypeId,
    });
  }

  deleteEvent(id: IDType) {
    return EventService.deleteEventEventEventIdDelete(id);
  }

  getGoalTypes() {
    return EventService.getGoalTypesEventTypeGoalGet();
  }

  getChanceTypes() {
    return EventService.getChanceTypesEventTypeChanceGet();
  }

  getCardTypes() {
    return EventService.getCardTypesEventTypeCardGet();
  }

  changeTel(tel: string) {
    return UserService.patchUserUserPatch({ tel });
  }

  postApplyInsider(teamIds: IDType[]) {
    return new CancelablePromise<User>((resolve, reject) => {
      const promises: CancelablePromise<User>[] = [];
      teamIds.forEach((teamId) => {
        promises.push(
          UserService.createGroupRequestUserGroupRequestPost({
            teamId,
            message: "",
          })
        );
      });
      let user: User;
      Promise.all(promises).then((results) => {
        results.forEach((res) => {
          user = res;
        });
        if (user) {
          resolve(user);
        } else {
          reject();
        }
      });
    });
  }

  resendCode() {
    return UserService.resendSmsUserResendSmsPost();
  }

  postRemoveInsider(userId: IDType, teamId: IDType) {
    return UserService.removeGroupFromUserUserGroupRemoveUserUserIdTeamTeamIdPost(
      userId,
      teamId
    );
  }

  postUpgradeInsider(userId: IDType, teamId: IDType) {
    return UserService.upgradeUserGroupUserGroupUpgradeUserUserIdTeamTeamIdPost(
      userId,
      teamId
    );
  }

  postDowngradeInsider(userId: IDType, teamId: IDType) {
    return UserService.downgradeUserGroupUserGroupDowngradeUserUserIdTeamTeamIdPost(
      userId,
      teamId
    );
  }

  checkVersion(version: string) {
    return AppService.getUpdateRequiredSrcUpdateRequiredVersionGet(version);
  }

  applyInsider(teamId: IDType) {
    return UserService.createGroupRequestUserGroupRequestPost({
      teamId,
      message: "",
    });
  }

  abortInsider(userId: IDType, teamId: IDType) {
    return new CancelablePromise<User>((resolve, reject) => {
      UserService.removeGroupFromUserUserGroupRemoveUserUserIdTeamTeamIdPost(
        userId,
        teamId
      ).then(() => {
        UserService.getUserMeUserMeGet().then((u) => {
          resolve(u);
        }, reject);
      }, reject);
    });
  }

  getInsidersByTeam(teamId: IDType) {
    return UserService.getGroupRequestsByTeamUserGroupTeamTeamIdGet(teamId);
  }

  getLineups() {
    return LineupService.getLineupTypesLineupTypesGet();
  }

  getLineup(matchId: IDType, team: MatchLocationEnum) {
    return LineupService.getLineupByMatchAndTeamLineupMatchMatchIdTeamGet(
      matchId,
      team
    );
  }

  putLineup(matchId: IDType, team: MatchLocationEnum, lineup: LineupCreate) {
    return LineupService.putLineupByMatchAndTeamLineupMatchMatchIdTeamPut(
      matchId,
      team,
      lineup
    );
  }

  createLocation(teamId: IDType, oldLocations: IDType[], body: LocationCreate) {
    return new CancelablePromise<Team>((resolve, reject) => {
      LocationService.createLocationLocationPost(body).then((location) => {
        TeamService.patchTeamTeamTeamIdPatch(teamId, {
          locations: oldLocations.concat([location.id]),
        }).then(resolve, reject);
      });
    });
  }

  postMatchInfo(
    matchId: IDType,
    newTime: Date,
    locationId: IDType | undefined
  ) {
    return MatchService.patchMatchMatchMatchIdPatch(matchId, {
      locationId,
      startTime: newTime.toISOString(),
    });
  }

  getLocation(q: string) {
    return SearchService.searchLocationSearchLocationQueryGet(q, 100, 0);
  }

  getMomt(matchId: IDType) {
    return ManOfTheMatchService.getManOfTheMatchVotingByMatchManOfTheMatchMatchIdGet(
      matchId,
      3
    );
  }

  voteMomt(vote: ManOfTheMatchVote) {
    return ManOfTheMatchService.voteMatchManOfTheMatchPost(3, vote);
  }

  getMatchHistoryOfMatch(matchId: IDType) {
    return MatchService.getMatchHistoryMatchHistoryMatchIdGet(matchId, 5);
  }

  fetchNews(aoiId: IDType, offset: number, limit: number, sports: SportEnum) {
    return BlogService.getBlogsBlogGet(limit, offset, aoiId, sports);
  }

  createNews(news: BlogCreate) {
    return BlogService.createBlogBlogPost(news);
  }

  fetchVideos(aoiId: IDType, offset: number, limit: number) {
    return ExternalVideoService.getExternalVideosExternalVideoGet(
      limit,
      offset,
      aoiId
    );
  }

  createVideo(createVideoRaw: ExternalVideoCreateRaw) {
    return ExternalVideoService.createExternalVideoExternalVideoRawPost(
      createVideoRaw
    );
  }

  fetchBulletins(aoiId: IDType, offset: number, limit: number) {
    return BulletinService.getBulletinsBulletinGet(aoiId, limit, offset);
  }

  fetchTransfers(aoiId: IDType, offset: number, limit: number) {
    return TransferService.getTransferTransferAreaOfInterestAreaOfInterestIdGet(
      aoiId,
      undefined,
      undefined,
      offset,
      limit
    );
  }

  fetchMixed(aoiId: IDType, sports: SportEnum) {
    return new CancelablePromise<{
      news: Blog[];
      transfers: Transfer[];
      videos: ExternalVideo[];
    }>((resolve, reject) => {
      BlogService.getBlogsBlogGet(5, 0, aoiId, sports).then((news) => {
        ExternalVideoService.getExternalVideosExternalVideoGet(
          5,
          0,
          aoiId
        ).then((videos) => {
          TransferService.getTransferTransferAreaOfInterestAreaOfInterestIdGet(
            aoiId,
            undefined,
            undefined,
            0,
            5
          ).then((transfers) => {
            resolve({ news, transfers, videos });
          }, reject);
        }, reject);
      }, reject);
    });
  }

  startMatch(id: IDType, minutes: number) {
    return MatchService.startMatchMatchStartMatchIdPost(id, minutes);
  }

  getAds(pos: AdPositionEnum) {
    return AdService.getAdsByPositionAdPositionPositionGet(pos);
  }

  getFeed(offset: number, limit: number) {
    return FeedService.getUserFeedFeedUserGet(offset, limit);
  }

  createMatch(match: MatchCreate) {
    return MatchService.createMatchMatchPost(match);
  }

  createMatchMultiple(matches: MatchCreate[]) {
    return MatchService.createMultipleMatchesMatchMultiplePost(matches);
  }

  csvImportMatch(matchImport: MatchImport[], dryRun: boolean) {
    return ImportService.importMatchImportMatchPost(matchImport, dryRun);
  }

  csvImportResult(taskId: string) {
    return ImportService.getMatchImportResultsImportMatchTaskIdGet(taskId);
  }

  patchMatch(id: IDType, match: MatchPatch) {
    return MatchService.patchMatchMatchMatchIdPatch(id, match);
  }

  patchAd(id: IDType, ad: AdPatch) {
    return AdService.patchAdAdAdIdPatch(id, ad);
  }

  createAd(ad: AdCreate) {
    return AdService.createAdAdPost(ad);
  }

  getAd(id: IDType) {
    return AdService.getAdAdAdIdGet(id);
  }

  patchLeague(id: IDType, league: LeaguePatch) {
    return LeagueService.patchLeagueLeagueLeagueIdPatch(id, league);
  }

  createLeague(league: LeagueCreate) {
    return LeagueService.createLeagueLeaguePost(league);
  }

  deleteLeague(id: IDType) {
    return LeagueService.deleteLeagueLeagueLeagueIdDelete(id);
  }

  searchLeagues(q: string, offset: number, limit: number) {
    return SearchService.searchLeagueSearchLeagueQueryGet(q, limit, offset);
  }

  searchTeams(q: string, offset: number, limit: number, leagueId?: IDType) {
    return SearchService.searchTeamSearchTeamQueryGet(
      q,
      limit,
      offset,
      leagueId
    );
  }

  searchAds(q: string, offset: number, limit: number) {
    return SearchService.searchAdSearchAdQueryGet(q, limit, offset);
  }

  searchUser(q: string, offset: number, limit: number) {
    return SearchService.searchUserSearchUserQueryGet(q, limit, offset);
  }

  deleteAd(id: IDType) {
    return AdService.deleteAdAdAdIdDelete(id);
  }

  searchLocations(q: string, offset: number, limit: number) {
    return SearchService.searchLocationSearchLocationQueryGet(q, limit, offset);
  }

  searchVideos(q: string, offset: number, limit: number) {
    return client().fetchVideos(1, offset, limit);
  }

  searchMatches(q: string, offset: number, limit: number) {
    return SearchService.searchMatchSearchMatchQueryGet(
      q,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      limit,
      offset
    );
  }

  searchMatchesFiltered(
    q: string,
    filters: MatchFilterType[],
    offset: number,
    limit: number
  ) {
    const aoi = filters.find((f) => f.type === "aoiId");
    const league = filters.find((f) => f.type === "leagueId");
    const team = filters.find((f) => f.type === "teamId");
    const dateFrom = filters.find((f) => f.type === "dateFrom");
    const dateTo = filters.find((f) => f.type === "dateTo");
    const matchDay = filters.find((f) => f.type === "matchDay");
    return SearchService.searchMatchSearchMatchQueryGet(
      q,
      aoi?.value,
      league?.value,
      team?.value,
      matchDay?.value,
      dateFrom
        ? formatDate(
            new Date(dateFrom.value),
            LanguageLocaleEnum.DE,
            true,
            false,
            true
          )
        : undefined,
      dateTo
        ? formatDate(
            new Date(dateTo.value),
            LanguageLocaleEnum.DE,
            true,
            false,
            true
          )
        : undefined,
      limit,
      offset
    );
  }

  deleteMatch(id: IDType) {
    return MatchService.deleteMatchMatchMatchIdDelete(id);
  }

  duplicateLeague(id: IDType, year: number, deactivateOld: boolean) {
    return LeagueService.duplicateLeagueLeagueDuplicateLeagueLeagueIdYearYearPost(
      id,
      year,
      deactivateOld
    );
  }

  imageLink(aspectRatio: number) {
    return UserMediaService.requestImageUploadUserMediaImagePost(aspectRatio);
  }

  uploadUserMedia(
    file: ClavaFile,
    progress?: (sent: number, total: number) => void
  ) {
    return new CancelablePromise<{
      id: string;
      variants: string[];
    }>((resolve, reject, onCancel) => {
      client()
        .imageLink(file.as)
        .then((link) => {
          const data = new FormData();
          data.append("file", file.file);
          const source = axios.CancelToken.source();
          onCancel(() => source.cancel("The user aborted a request."));
          const headers = {
            "Content-Type": "multipart/form-data",
          };
          const config: AxiosRequestConfig = {
            onUploadProgress: (evt: AxiosProgressEvent) => {
              if (!!evt.progress && !!evt.total && !!progress)
                progress(evt.progress, evt.total);
            },
            method: "POST",
            url: link.url,
            data,
            headers,
          };
          try {
            axios.request(config).then(
              (response) => {
                if (response.status === 200 && response.data.success) {
                  resolve(response.data.result);
                } else {
                  reject(`Failed to uplaod: ${response.statusText}`);
                }
              },
              (error) => {
                reject(`Failed to uplaod: ${error}`);
              }
            );
          } catch (e) {
            reject(`Failed to uplaod: ${e}`);
          }
        });
    });
  }

  uploadFile(
    f: ClavaFile,
    imageType: ImageTypeEnum,
    caption: string,
    progress: (event: ProgressEvent) => void
  ): CancelablePromise<File> {
    const data = new FormData();
    data.append("file", f.file);

    return new CancelablePromise<File>(async (resolve, reject, onCancel) => {
      const source = axios.CancelToken.source();
      onCancel(() => source.cancel("The user aborted a request."));

      const { token } = Client;

      const headers = {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      };
      const url = `https://${client().getEndpoint()}/upload/image?caption=${caption}&filename=${
        f.filename
      }&image_type_enum=${imageType}`;
      const config: AxiosRequestConfig = {
        method: "POST",
        url,
        data,
        headers,
      };

      addLog("req", { Post: url, Body: data });
      console.log("\x1b[34m%s\x1b[0m", `POST: ${url}`);
      try {
        axios.request(config).then(
          (response) => {
            addLog("response", response);
            if (response.status >= 200 && response.status < 300) {
              const body = response.status !== 204 ? response.data : undefined;
              resolve(body);
            } else {
              reject(`Failed to uplaod: ${response.statusText}`);
            }
          },
          (error) => {
            reject(`Failed to uplaod: ${error}`);
          }
        );
      } catch (e) {
        reject(`Failed to uplaod: ${e}`);
      }
    });
  }

  clearCache(key: string) {
    return AdministrationService.clearCacheAdminClearCachePost(key);
  }

  forceRecalculateSquad(key: string) {
    return AdministrationService.forceRecalculateSquadsAdminForceRecalculateSquadsPost(
      key
    );
  }

  forceRecalculateStatistics(key: string) {
    return AdministrationService.recalculateAllStatisticsAdminRecalculateAllStatisticsPost(
      key
    );
  }

  forceRecalculateStanding(key: string) {
    return AdministrationService.updateAllStandingsAdminRefreshAllStandingsPost(
      key
    );
  }

  confirmUserEmail(email: string, key: string) {
    return AdministrationService.confirmUserEmailAdminConfirmUserEmailUserEmailPost(
      email,
      key
    );
  }

  giveUserScope(email: string, key: string, scope: ScopeEnum) {
    return AdministrationService.makeUserAdminAdminGiveUserScopeUserIdPost(
      email,
      scope,
      key
    );
  }

  getBadges() {
    return BadgeService.getBadgesBadgeGet();
  }

  patchBadge(id: BadgeTypeEnum, badge: BadgePatch) {
    return BadgeService.patchBadgesBadgeBadgeTypePatch(id, badge);
  }

  createBadge(badge: BadgeCreate) {
    return BadgeService.createBadgesBadgePost(badge);
  }

  giveBadge(badge: UserBadgeCreateDelete) {
    return BadgeService.giveUserBadgeBadgeGivePut(badge);
  }

  generateReport(aoiId: number, matchday?: number) {
    return ExportService.exportPlayerScoreExportPlayerScoreAreaOfInterestIdPost(
      aoiId,
      matchday
    );
  }

  removeBadge(badge: UserBadgeCreateDelete) {
    return BadgeService.removeUserBadgeBadgeRemoveDelete(badge);
  }

  bulkSearch(searchType: SearchTypeEnum, searchBody: SearchRequest[]) {
    return SearchService.bulkSearchSearchBulkPost(searchType, searchBody);
  }

  addTeamToLeague(leagueId: IDType, teamIds: IDType[]) {
    return new CancelablePromise<League>(async (resolve, reject) => {
      if (teamIds.length !== 0) {
        const promises: Promise<League>[] = [];
        teamIds.forEach((teamId) => {
          promises.push(
            LeagueService.addTeamToLeagueLeagueAddTeamPost(leagueId, teamId)
          );
        });
        Promise.allSettled(promises).then((results) => {
          let league: League | undefined;
          results.forEach((res) => {
            if (res.status === "fulfilled") league = res.value;
          });
          if (league) resolve(league);
          else reject("No team added");
        });
      }
    });
  }

  removeTeamFromLeague(leagueId: IDType, teamIds: IDType[]) {
    return new CancelablePromise<League>(async (resolve, reject) => {
      if (teamIds.length !== 0) {
        const promises: Promise<League>[] = [];
        teamIds.forEach((teamId) => {
          promises.push(
            LeagueService.removeTeamFromLeagueLeagueRemoveTeamPost(
              leagueId,
              teamId
            )
          );
        });
        Promise.allSettled(promises).then((results) => {
          let league: League | undefined;
          results.forEach((res) => {
            if (res.status === "fulfilled") league = res.value;
          });
          if (league) resolve(league);
          else reject("No team removed");
        });
      }
    });
  }

  bulkDeleteMatches(ids: IDType[]) {
    return new CancelablePromise<IDType[]>((resolve, reject) => {
      const promises: Promise<number>[] = [];
      ids.forEach((id) => {
        promises.push(MatchService.deleteMatchMatchMatchIdDelete(id));
      });
      Promise.allSettled(promises).then((results) => {
        const retval: IDType[] = [];
        results.forEach((result) => {
          if (result.status === "fulfilled") {
            retval.push(result.value);
          }
        });
        resolve(retval);
      }, reject);
    });
  }

  bulkPatchMatches(patches: MatchPatch[]) {
    return new CancelablePromise<Match[]>((resolve, reject) => {
      const promises: Promise<Match>[] = [];
      patches.forEach((patch) => {
        const id = patch.locationId;
        patch.locationId = undefined;
        if (!id) return;
        promises.push(MatchService.patchMatchMatchMatchIdPatch(id, patch));
      });
      Promise.allSettled(promises).then((results) => {
        const retval: Match[] = [];
        results.forEach((result) => {
          if (result.status === "fulfilled") {
            retval.push(result.value);
          }
        });
        resolve(retval);
      }, reject);
    });
  }

  adStats() {
    return OutService.getOutSummaryOutSummaryGet(
      "2022-04-12T09:21:32.688Z",
      "2030-04-12T09:21:32.688Z"
    );
  }

  private filterAoi(
    aois: AreaOfInterest[],
    id: IDType,
    resolve: (value: PromiseLike<unknown> | unknown) => void,
    reject: (reason?: any) => void
  ): void {
    const aoisFilter = aois.filter((aoi) => aoi.id === id);
    if (aoisFilter.length === 1) {
      resolve(aoisFilter[0]);
    } else {
      reject(
        new ApiError(
          { url: "aoi", method: "GET" },
          {
            body: "asd",
            ok: false,
            status: 420,
            statusText: "invalid_id",
            url: "locale",
          },
          "invalid_id"
        )
      );
    }
  }
}

const client = Client.getInstance;
export default client;
// rel oad
