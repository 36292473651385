/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { MediaUpload } from '../models/MediaUpload';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class UserMediaService {
    /**
     * Request Image Upload
     * @param aspectRatio
     * @returns MediaUpload Successful Response
     * @throws ApiError
     */
    public static requestImageUploadUserMediaImagePost(
        aspectRatio: number,
    ): CancelablePromise<MediaUpload> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/user_media/image',
            query: {
                'aspectRatio': aspectRatio,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Request Video Upload
     * @param aspectRatio
     * @returns MediaUpload Successful Response
     * @throws ApiError
     */
    public static requestVideoUploadUserMediaVideoPost(
        aspectRatio: number,
    ): CancelablePromise<MediaUpload> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/user_media/video',
            query: {
                'aspectRatio': aspectRatio,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
}
