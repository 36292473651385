import React, { useCallback, useContext, useRef, useState } from "react";
import { Button, FormGroup, Label } from "reactstrap";
import {
  Ad,
  AdCreate,
  AdPatch,
  AdPositionEnum,
  Image,
} from "../../../../../client/api";
import { translate } from "../../../../../config/translator";
import ClavaPicker from "../../../../components/Form/ClavaPicker";
import DateInput from "../../DateInput";
import CheckboxInput from "../../CheckboxInput";
import TextInput from "../../TextInput";
import FileInput, { ClavaFile } from "../../FileInput";
import { formatDate } from "../../../../../config/utils";
import { ClavaContext } from "../../../../../config/contexts";
import client from "../../../../../client";

type EditCreateProps =
  | {
      selectedAd: undefined;
      onSubmit: (ad: AdCreate) => void;
    }
  | {
      selectedAd: Ad;
      onSubmit: (ad: AdPatch) => void;
    };

const EditCreateAd: React.FC<EditCreateProps> = ({ selectedAd, onSubmit }) => {
  const { l } = useContext(ClavaContext);
  const [name, setName] = useState(selectedAd?.name ?? "");
  const [color, setColor] = useState<string>(selectedAd?.color ?? "");
  const [position, setPosition] = useState<AdPositionEnum>(
    selectedAd?.position ?? AdPositionEnum.HOME_MATCH
  );
  const [priority, setPriority] = useState(selectedAd?.priority ?? 1);
  const [url, setUrl] = useState(selectedAd?.url ?? "");
  const [start, setStart] = useState<Date>(
    new Date(selectedAd?.start ?? new Date().getTime())
  );
  const [stop, setStop] = useState<Date>(
    new Date(selectedAd?.stop ?? new Date().getTime())
  );
  const [paused, setPaused] = useState<boolean>(selectedAd?.paused ?? false);
  const [fileMobile, setFileMobile] = useState<Image | undefined>(
    selectedAd?.imageMobile ?? undefined
  );
  const [fileDesktop, setFileDesktop] = useState<Image | undefined>(
    selectedAd?.imageDesktop ?? undefined
  );
  const progressTarget = useRef<number>(-1);
  const [fileUploadProgress, setFileUploadProgress] = useState(0);
  const [fileUploadError, setFileUploadError] = useState<string>();
  const [useSameFile, setUseSameFile] = useState(false);
  const [trent, setTrent] = useState(false);
  const [sued, setSued] = useState(true);

  const setPositionCont = useCallback((val: string) => {
    if (val === AdPositionEnum.TEAM_OF_THE_WEEK) setUseSameFile(true);
    setPosition(val as AdPositionEnum);
  }, []);

  const onSubmitCont = useCallback(() => {
    if (selectedAd)
      onSubmit({
        url: url === selectedAd.url ? undefined : url,
        start: formatDate(start, l, true),
        stop: formatDate(stop, l, true),
        name,
        priority,
        position,
        imageMobileId: fileMobile?.id,
        imageDesktopId: fileDesktop ? fileDesktop.id : fileMobile?.id,
        color,
        paused,
        areaOfInterestIds:
          sued && trent ? [1, 2] : sued ? [1] : trent ? [2] : [],
      });
    else if (fileMobile) {
      onSubmit({
        url,
        start: formatDate(start, l, true),
        stop: formatDate(stop, l, true),
        name,
        priority,
        position,
        imageMobileId: fileMobile?.id,
        imageDesktopId: fileDesktop ? fileDesktop.id : fileMobile?.id,
        color,
        paused,
        areaOfInterestIds:
          sued && trent ? [1, 2] : sued ? [1] : trent ? [2] : [],
      });
    }
  }, [
    color,
    fileDesktop,
    fileMobile,
    l,
    name,
    onSubmit,
    paused,
    position,
    priority,
    selectedAd,
    start,
    stop,
    sued,
    trent,
    url,
  ]);
  const onSetFileMobile = useCallback(
    (file: ClavaFile | undefined) => {
      if (file) {
        progressTarget.current = 0;

        client()
          .uploadUserMedia(file, (progress, total) => {
            setFileUploadProgress(Math.round(progress / total));
          })
          .then(
            (data) => {
              if (data) {
                setFileMobile({
                  id: data.id,
                  url: data.variants[0],
                  aspectRatio: file.as,
                  uploadDate: "",
                });
              } else {
                setFileUploadError(translate("uploadError", l));
              }
            },
            (error) => {
              setFileUploadError(error);
            }
          );
      } else {
        setFileMobile(undefined);
      }
    },
    [l, name, position]
  );
  const onSetFileDesktop = useCallback(
    (file: ClavaFile | undefined) => {
      if (file) {
        progressTarget.current = 1;
        client()
          .uploadUserMedia(file, (progress, total) => {
            setFileUploadProgress(Math.round(progress / total));
          })
          .then(
            (data) => {
              if (data) {
                setFileDesktop({
                  id: data.id,
                  url: data.variants[0],
                  aspectRatio: file.as,
                  uploadDate: "",
                });
              } else {
                setFileUploadError(translate("uploadError", l));
              }
            },
            (error) => {
              setFileUploadError(error);
            }
          );
      } else {
        setFileDesktop(undefined);
      }
    },
    [l, name, position]
  );
  return (
    <>
      <TextInput value={name} onChange={setName} name="title" label="title" />
      <FormGroup>
        <Label>{translate("adPosition", l)}</Label>
        <ClavaPicker
          disabled={!!fileDesktop || !!fileMobile}
          items={[
            {
              key: AdPositionEnum.HOME_MATCH,
              label: translate("homeMatchAd", l),
              value: AdPositionEnum.HOME_MATCH,
            },
            {
              key: AdPositionEnum.MATCH_HISTORY_BOTTOM,
              label: translate("matchHistoryBottomAd", l),
              value: AdPositionEnum.MATCH_HISTORY_BOTTOM,
            },
            {
              key: AdPositionEnum.LEAGUE_MATCH_MATCH,
              label: translate("leagueMatchAd", l),
              value: AdPositionEnum.LEAGUE_MATCH_MATCH,
            },
            {
              key: AdPositionEnum.USER_FEED,
              label: translate("feed", l),
              value: AdPositionEnum.USER_FEED,
            },
            {
              key: AdPositionEnum.TEAM_OF_THE_WEEK,
              label: translate("tow", l),
              value: AdPositionEnum.TEAM_OF_THE_WEEK,
            },
            {
              key: AdPositionEnum.STANDINGS,
              label: translate("standingsAd", l),
              value: AdPositionEnum.STANDINGS,
            },
          ]}
          disabledMsg={
            !!fileDesktop || !!fileMobile
              ? translate("deleteImageToChoose", l)
              : ""
          }
          value={position}
          onValueChange={setPositionCont}
        />
      </FormGroup>

      <CheckboxInput
        label="southtyrol"
        onChange={setSued}
        name="suedtirol"
        value={sued}
      />
      <CheckboxInput
        label="trentino"
        onChange={setTrent}
        name="trentino"
        value={trent}
      />
      <DateInput
        label="startDate"
        onChange={setStart}
        value={start}
        name="startDate"
        type="datetime"
      />
      <DateInput
        label="stopDate"
        onChange={setStop}
        value={stop}
        name="stopDate"
        type="datetime"
      />
      <CheckboxInput
        label="paused"
        onChange={setPaused}
        name="priority"
        value={paused}
      />
      <TextInput value={url} onChange={setUrl} name="url" label="url" />
      <TextInput value={color} onChange={setColor} name="color" label="color" />
      <TextInput
        value={priority}
        onChange={setPriority}
        name="priority"
        label="priority"
        multiline
      />
      {fileUploadError && (
        <span className="text-danger">{fileUploadError}</span>
      )}
      <FileInput
        name="fileMobile"
        onChange={onSetFileMobile}
        progress={progressTarget.current === 0 ? fileUploadProgress : 0}
        preview={fileMobile?.url ?? ""}
      />
      <CheckboxInput
        label="useSameFile"
        onChange={setUseSameFile}
        name="useSameFile"
        disabled={position === AdPositionEnum.TEAM_OF_THE_WEEK}
        value={useSameFile}
      />
      {!useSameFile && (
        <FileInput
          name="fileDesktop"
          onChange={onSetFileDesktop}
          progress={progressTarget.current === 1 ? fileUploadProgress : 0}
          preview={fileDesktop?.url ?? ""}
        />
      )}
      <Button color="primary" onClick={onSubmitCont}>
        {translate("submit", l)}
      </Button>
    </>
  );
};

export default EditCreateAd;
