import React, { useCallback, useContext, useMemo, useState } from "react";
import { useParams } from "react-router";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMobileAndroid } from "@fortawesome/pro-regular-svg-icons";
import LeagueMatches from "../../components/LeagueMatches";
import Leagues from "../../components/League";
import SportsTypePicker from "../../components/SportsTypePicker";
import { APP_STORE_URL } from "../../../config/constants";
import { translate } from "../../../config/translator";
import { ClavaContext } from "../../../config/contexts";

const Home: React.FC = () => {
  const { leagueId } = useParams();
  const { l } = useContext(ClavaContext);
  const [hidePopup, setHidePopup] = useState(false);
  const closePopup = useCallback(() => {
    setHidePopup(true);
  }, []);
  const realLeagueId = useMemo(() => {
    if (!leagueId) return -1;
    const id = parseInt(leagueId, 10);
    return Number.isNaN(id) ? -1 : id;
  }, [leagueId]);
  return (
    <>
      <SportsTypePicker />
      <div className="home">
        <Leagues small leagueId={realLeagueId} />
        <LeagueMatches leagueId={realLeagueId} />
      </div>
      {!hidePopup && (
        <>
          {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
          <div
            className="modal-backdrop fade show hidden-xl"
            onClick={closePopup}
          />
          <div className="pls-load-app hidden-xl">
            <a href={APP_STORE_URL}>
              <FontAwesomeIcon
                icon={faMobileAndroid}
                size="3x"
                className="text-primary"
              />
              <h5 className="text-center text-primary">{`${translate(
                "loadApp",
                l
              )}`}</h5>
            </a>
          </div>
        </>
      )}
    </>
  );
};

// relo ad
export default Home;
