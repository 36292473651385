import React, { useCallback, useContext, useRef, useState } from "react";
import { Button, Col, Row } from "reactstrap";
import { Blog, BlogCreate, Image, SportEnum } from "../../../../../client/api";
import { translate } from "../../../../../config/translator";
import TextInput from "../../TextInput";
import FileInput, { ClavaFile } from "../../FileInput";
import { generatePW } from "../../../../../config/utils";
import { ClavaContext } from "../../../../../config/contexts";
import client from "../../../../../client";
import CheckboxInput from "../../CheckboxInput";
import ClavaPicker from "../../../../components/Form/ClavaPicker";

type EditCreateProps =
  | {
      selectedNews: undefined;
      onSubmit: (badge: BlogCreate) => void;
    }
  | {
      selectedNews: Blog;
      onSubmit: (badge: BlogCreate) => void;
    };

const EditCreateNews: React.FC<EditCreateProps> = ({
  selectedNews,
  onSubmit,
}) => {
  const { l } = useContext(ClavaContext);

  const [titleDE, setTitleDE] = useState(selectedNews?.title.textDE ?? "");
  const [titleIT, setTitleIT] = useState(selectedNews?.title.textIT ?? "");
  const [titleEN, setTitleEN] = useState(selectedNews?.title.textEN ?? "");
  const [summaryDE, setSummaryDE] = useState(
    selectedNews?.summary?.textDE ?? ""
  );
  const [summaryIT, setSummaryIT] = useState(
    selectedNews?.summary?.textIT ?? ""
  );
  const [summaryEN, setSummaryEN] = useState(
    selectedNews?.summary?.textEN ?? ""
  );
  const [bodyDE, setBodyDE] = useState(selectedNews?.body.textDE ?? "");
  const [bodyIT, setBodyIT] = useState(selectedNews?.body.textIT ?? "");
  const [bodyEN, setBodyEN] = useState(selectedNews?.body.textEN ?? "");
  const [link, setLink] = useState(selectedNews?.link ?? "");
  const [uid, setUid] = useState(generatePW(32, false));
  const [trent, setTrent] = useState(false);
  const [sued, setSued] = useState(true);

  const [image, setImage] = useState<Image | undefined>(
    selectedNews?.image ?? undefined
  );
  const [sports, setSports] = useState(SportEnum.SOCCER);
  const progressTarget = useRef<number>(-1);
  const [fileUploadProgress, setFileUploadProgress] = useState(0);
  const [fileUploadError, setFileUploadError] = useState<string>();
  const submitted = useRef(false);
  const onSubmitCont = useCallback(() => {
    if (image && !submitted.current) {
      submitted.current = true;
      onSubmit({
        title: {
          textEN: titleEN,
          textIT: titleIT,
          textDE: titleDE,
        },
        areas_of_interest:
          sued && trent ? [1, 2] : sued ? [1] : trent ? [2] : [],
        summary: {
          textEN: summaryEN,
          textIT: summaryIT,
          textDE: summaryDE,
        },
        body: {
          textEN: bodyEN,
          textIT: bodyIT,
          textDE: bodyDE,
        },
        imageId: image.id,
        sports,
        link,
        uid,
      });
    }
  }, [
    image,
    onSubmit,
    titleEN,
    titleIT,
    titleDE,
    sued,
    trent,
    summaryEN,
    summaryIT,
    summaryDE,
    bodyEN,
    bodyIT,
    bodyDE,
    sports,
    link,
    uid,
  ]);
  const onSetImage = useCallback(
    (file: ClavaFile | undefined) => {
      if (file) {
        progressTarget.current = 0;
        client()
          .uploadUserMedia(file, (progress, total) => {
            setFileUploadProgress(Math.round(progress / total));
          })
          .then(
            (data) => {
              if (data) {
                setImage({
                  id: data.id,
                  url: data.variants[0],
                  uploadDate: "",
                  aspectRatio: file.as,
                });
              } else {
                setFileUploadError(translate("uploadError", l));
              }
            },
            (error) => {
              setFileUploadError(error);
            }
          );
      } else {
        setImage(undefined);
      }
    },
    [l, titleDE]
  );

  return (
    <>
      <Row>
        <Col>
          <TextInput
            value={titleDE}
            onChange={setTitleDE}
            name="titleDE"
            label="titleDE"
          />
        </Col>
        <Col>
          <TextInput
            value={titleIT}
            onChange={setTitleIT}
            name="titleIT"
            label="titleIT"
          />
        </Col>
        <Col>
          <TextInput
            value={titleEN}
            onChange={setTitleEN}
            name="titleEN"
            label="titleEN"
          />
        </Col>
      </Row>
      <TextInput
        value={summaryDE}
        onChange={setSummaryDE}
        name="summaryDE"
        label="summaryDE"
        multiline
      />
      <TextInput
        value={summaryIT}
        onChange={setSummaryIT}
        name="summaryIT"
        label="summaryIT"
        multiline
      />
      <TextInput
        value={summaryEN}
        onChange={setSummaryEN}
        name="summaryEN"
        label="summaryEN"
        multiline
      />
      <TextInput
        value={bodyDE}
        onChange={setBodyDE}
        name="bodyDE"
        label="bodyDE"
        multiline
      />
      <TextInput
        value={bodyIT}
        onChange={setBodyIT}
        name="bodyIT"
        label="bodyIT"
        multiline
      />
      <TextInput
        value={bodyEN}
        onChange={setBodyEN}
        name="bodyEN"
        label="bodyEN"
        multiline
      />
      <Row>
        <Col>
          <CheckboxInput
            label="southtyrol"
            onChange={setSued}
            name="suedtirol"
            value={sued}
          />
        </Col>
        <Col>
          <CheckboxInput
            label="trentino"
            onChange={setTrent}
            name="trentino"
            value={trent}
          />
        </Col>
      </Row>
      <ClavaPicker
        items={[
          {
            key: SportEnum.SOCCER,
            label: translate(SportEnum.SOCCER, l),
            value: SportEnum.SOCCER,
          },
          {
            key: SportEnum.HOCKEY,
            label: translate(SportEnum.HOCKEY, l),
            value: SportEnum.HOCKEY,
          },
        ]}
        value={sports}
        onValueChange={(sp) => {
          setSports(sp as SportEnum);
        }}
      />
      <Row>
        <Col xs={12} md={6}>
          <TextInput label="link" onChange={setLink} value={link} name="link" />
        </Col>

        <Col xs={12} md={6}>
          <TextInput label="uid" onChange={setUid} value={uid} name="uid" />
        </Col>
      </Row>
      {fileUploadError && (
        <span className="text-danger">{fileUploadError}</span>
      )}
      <FileInput
        name="picture"
        onChange={onSetImage}
        progress={progressTarget.current === 0 ? fileUploadProgress : 0}
        preview={image?.url ?? ""}
      />
      <Button color="primary" onClick={onSubmitCont}>
        {translate("submit", l)}
      </Button>
    </>
  );
};

export default EditCreateNews;
