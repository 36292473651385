/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum FormatTypeEnum {
    SMALL = 'small',
    MEDIUM = 'medium',
    LARGE = 'large',
    XL = 'xl',
}
