import React, { MutableRefObject, useContext } from "react";
import { Col, Row } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFutbol, faHockeyPuck } from "@fortawesome/pro-regular-svg-icons";
import {
  CardTypeEnum,
  ChanceTypeEnum,
  EventType,
  EventTypeEnum,
  GoalTypeEnum,
  SportEnum,
} from "../../../../client/api";
import { ClavaContext } from "../../../../config/contexts";
import { IDType } from "../../../../config/types";
import { showTranslated, translate } from "../../../../config/translator";

const MatchEvent: React.FC<{
  event: EventType;
  team1Id: IDType;
  standing1: MutableRefObject<number>;
  sports: SportEnum;
  team2Id: IDType;
  standing2: MutableRefObject<number>;
}> = ({ event, team2Id, sports, team1Id, standing1, standing2 }) => {
  const { l } = useContext(ClavaContext);

  return (
    <Row className="match-event py-1" noGutters>
      <Col xs={2}>
        <span>{`${event.minute ?? translate("noMinutesShort", l)}'`}</span>
      </Col>
      <Col xs={8} md={4}>
        {event.teamId === team1Id && (
          <span className="text-start player">
            {event.player
              ? `${event.player.familyName} ${event.player.givenName.slice(
                  0,
                  1
                )}.`
              : "..."}
          </span>
        )}
        {event.type === EventTypeEnum.GOAL &&
          !!event.assist &&
          event.teamId === team1Id && (
            <span className="text-start assist">
              {`${event.assist.familyName} ${event.assist.givenName.slice(
                0,
                1
              )}.`}
              {event.goalType && (
                <>
                  <br />
                  {showTranslated(event.goalType.translation, l)}
                </>
              )}
            </span>
          )}
        {event.type === EventTypeEnum.GOAL &&
          !event.assist &&
          !!event.goalType &&
          event.teamId === team1Id && (
            <span className="text-start assist">
              {showTranslated(event.goalType.translation, l)}
            </span>
          )}
        {event.type === EventTypeEnum.CHANCE &&
          !!event.chanceType &&
          event.teamId === team1Id && (
            <span className="text-start assist">
              {showTranslated(event.chanceType.translation, l)}
            </span>
          )}
        {event.teamId === team2Id && (
          <span className="text-right player hidden-xl">
            {event.player
              ? `${event.player.familyName} ${event.player.givenName.slice(
                  0,
                  1
                )}.`
              : "..."}
          </span>
        )}
        {event.type === EventTypeEnum.GOAL &&
          !!event.assist &&
          event.teamId === team2Id && (
            <span className="text-right assist hidden-xl">
              {`${event.assist.familyName} ${event.assist.givenName.slice(
                0,
                1
              )}.`}
              {event.goalType &&
                ` ${showTranslated(event.goalType.translation, l)}`}
            </span>
          )}
        {event.type === EventTypeEnum.GOAL &&
          !event.assist &&
          !!event.goalType &&
          event.teamId === team2Id && (
            <span className="text-right assist hidden-xl">
              {showTranslated(event.goalType.translation, l)}
            </span>
          )}
        {event.type === EventTypeEnum.CHANCE &&
          !!event.chanceType &&
          event.teamId === team2Id && (
            <span className="text-right assist hidden-xl">
              {showTranslated(event.chanceType.translation, l)}
            </span>
          )}
      </Col>
      <Col xs={2} className="event-icon">
        {event.type === EventTypeEnum.GOAL ? (
          <FontAwesomeIcon
            icon={sports === SportEnum.SOCCER ? faFutbol : faHockeyPuck}
            className={
              event.goalType && event.goalType.key === GoalTypeEnum.OWN_GOAL
                ? "own-goal"
                : "goal"
            }
          />
        ) : event.type === EventTypeEnum.CARD ? (
          <div
            className={`card-event ${
              event.cardType.key === CardTypeEnum.RED
                ? "red"
                : event.cardType.key === CardTypeEnum.YELLOW_RED
                ? "yellow-red"
                : "yellow"
            }`}
          />
        ) : event.type === EventTypeEnum.CHANCE ? (
          event.chanceType.key === ChanceTypeEnum.POST ? (
            <div className="post">
              <FontAwesomeIcon
                icon={sports === SportEnum.SOCCER ? faFutbol : faHockeyPuck}
                color="red"
                className="post-ball"
              />
            </div>
          ) : (
            <FontAwesomeIcon
              icon={sports === SportEnum.SOCCER ? faFutbol : faHockeyPuck}
              color="red"
            />
          )
        ) : (
          <FontAwesomeIcon
            icon={sports === SportEnum.SOCCER ? faFutbol : faHockeyPuck}
          />
        )}
        {event.type === EventTypeEnum.GOAL && (
          <span className="standing">
            {event.teamId === team1Id
              ? `${++standing1.current} - ${standing2.current}`
              : `${standing1.current} - ${++standing2.current}`}
          </span>
        )}
      </Col>
      <Col xs={7} md={4} className="hidden-xl-max">
        <span className="text-right player">
          {event.teamId === team2Id
            ? event.player
              ? `${event.player.familyName} ${event.player.givenName.slice(
                  0,
                  1
                )}.`
              : "..."
            : ""}
        </span>
        {event.type === EventTypeEnum.GOAL &&
          !!event.assist &&
          event.teamId === team2Id && (
            <span className="text-right assist">
              {`${event.assist.familyName} ${event.assist.givenName.slice(
                0,
                1
              )}.`}
              {event.goalType &&
                ` ${showTranslated(event.goalType.translation, l)}`}
            </span>
          )}
        {event.type === EventTypeEnum.GOAL &&
          !event.assist &&
          !!event.goalType &&
          event.teamId === team2Id && (
            <span className="text-right assist">
              {showTranslated(event.goalType.translation, l)}
            </span>
          )}
        {event.type === EventTypeEnum.CHANCE &&
          !!event.chanceType &&
          event.teamId === team2Id && (
            <span className="text-right assist">
              {showTranslated(event.chanceType.translation, l)}
            </span>
          )}
      </Col>
    </Row>
  );
};

export default MatchEvent;
// relo  ad
