import React, { useContext, useEffect, useMemo, useState } from "react";
import { Col, Row } from "reactstrap";
import { NavLink } from "react-router-dom";
import { ConnectedProps } from "react-redux";
import { useParams } from "react-router";
import TeamName from "../../Team/TeamName";
import MatchStatusDisplay from "../MatchStatusDisplay";
import {
  fullMatchLengthMinutes,
  isAdmin,
  matchStatusDate,
} from "../../../../config/utils";
import { translate } from "../../../../config/translator";
import { connector } from "./redux";
import { ClavaContext } from "../../../../config/contexts";
import Match from "../index";
import { parseParams } from "../../../../config/routes";
import { SportEnum } from "../../../../client/api";

const MatchSmall: React.FC<ConnectedProps<typeof connector>> = ({
  match,
  cancelled,
  startDate,
  thisMatchId,
  goal1,
  goal2,
}) => {
  const { user, l } = useContext(ClavaContext);
  const params = useParams();
  const { matchId } = params;
  const endTime = useMemo(() => {
    if (!match) {
      return 0;
    }
    return new Date(match.endTime).getTime();
  }, [match]);
  const [status, setStatus] = useState(
    matchStatusDate(startDate, fullMatchLengthMinutes(match), endTime)
  );
  useEffect(() => {
    const interval = setInterval(() => {
      setStatus(
        matchStatusDate(startDate, fullMatchLengthMinutes(match), endTime)
      );
    }, 5000);
    return () => {
      clearInterval(interval);
    };
  }, [endTime, match, startDate]);
  const realMatchId = useMemo(() => {
    if (!matchId) return -1;
    const id = parseInt(matchId, 10);
    return Number.isNaN(id) ? -1 : id;
  }, [matchId]);
  const live = typeof status === "number";
  if (!match) return null;
  if (realMatchId === match.id) {
    return <Match match={match} />;
  }
  return (
    <NavLink
      to={parseParams({ matchId: thisMatchId }, params)}
      className="match-small"
    >
      <Row>
        <Col xs={2} className="match-status">
          {cancelled ? (
            <span className="text-danger">
              {translate("cancelledShort", l)}
            </span>
          ) : (
            <MatchStatusDisplay
              startDate={startDate}
              endTime={endTime}
              sectionAmount={match.league.matchSectionAmount}
              sectionDuration={match.league.matchSectionDurationMinutes}
              pauseDuration={match.league.matchSectionPauseDurationMinutes}
              hideLive={match.league.sports === SportEnum.SOCCER}
              hideMinute={match.league.sports === SportEnum.HOCKEY}
            />
          )}
        </Col>
        <Col xs={8}>
          <TeamName
            team={match.team1}
            goal={status === "future" ? undefined : goal1}
            live={live}
          />
          <TeamName
            team={match.team2}
            goal={status === "future" ? undefined : goal2}
            live={live}
          />
        </Col>
        <Col xs={2} className="fav-icon">
          {isAdmin(user) && (
            <span className="text-muted">{`[${match.id}]`}</span>
          )}
        </Col>
      </Row>
    </NavLink>
  );
};

export default connector(MatchSmall);
// rel oad
