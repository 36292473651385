import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { connect } from "react-redux";
import { RootState } from "../../../store";
import { SportEnum } from "../../../client/api";
import {
  MatchActionTypes,
  UserActionTypes,
} from "../../../store/actions/types";

const mapper = (dispatch: ThunkDispatch<any, any, AnyAction>) => ({
  changeSportsType: (sport: SportEnum) => {
    dispatch({ type: UserActionTypes.SET_SPORTS_TYPE, payload: sport });
    dispatch({ type: MatchActionTypes.RESET });
  },
});

const props = (state: RootState, prevProps: { onChange?: () => void }) => ({
  currentSportsType: state.user.sportsType,
  ...prevProps,
});

export const connector = connect(props, mapper);
