import React, { useCallback, useContext } from "react";
import { ConnectedProps } from "react-redux/es/exports";
import { Button } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFutbol,
  faHockeyPuck,
  faVolleyballBall,
} from "@fortawesome/pro-regular-svg-icons";
import { useNavigate } from "react-router";
import { connector } from "./redux";
import { SportEnum } from "../../../client/api";
import { translate } from "../../../config/translator";
import { ClavaContext } from "../../../config/contexts";

const SportsTypePicker: React.FC<ConnectedProps<typeof connector>> = ({
  currentSportsType,
  changeSportsType,
  onChange,
}) => {
  const { l } = useContext(ClavaContext);
  const navigation = useNavigate();
  const onPressSoccer = useCallback(() => {
    if (onChange) {
      onChange();
    }
    changeSportsType(SportEnum.SOCCER);
    navigation("/home");
  }, [changeSportsType, navigation, onChange]);
  const onPressVolley = useCallback(() => {
    if (onChange) {
      onChange();
    }
    changeSportsType(SportEnum.VOLLEYBALL);
    navigation("/home");
  }, [changeSportsType, navigation, onChange]);
  const onPressHockey = useCallback(() => {
    if (onChange) {
      onChange();
    }
    changeSportsType(SportEnum.HOCKEY);
    navigation("/home");
  }, [changeSportsType, navigation, onChange]);
  return (
    <div className="sports-picker-container">
      <div>
        <div>
          <Button
            onClick={onPressSoccer}
            color={
              currentSportsType === SportEnum.SOCCER ? "primary" : "secondary"
            }
            className="sports-picker"
          >
            <FontAwesomeIcon icon={faFutbol} />
            <span>{translate(SportEnum.SOCCER, l)}</span>
          </Button>
        </div>
        <div>
          <Button
            onClick={onPressHockey}
            color={
              currentSportsType === SportEnum.HOCKEY ? "primary" : "secondary"
            }
            className="sports-picker"
          >
            <FontAwesomeIcon icon={faHockeyPuck} />
            <span>{translate(SportEnum.HOCKEY, l)}</span>
          </Button>
        </div>
        <div>
          <Button
            onClick={onPressVolley}
            color={
              currentSportsType === SportEnum.VOLLEYBALL
                ? "primary"
                : "secondary"
            }
            className="sports-picker"
          >
            <FontAwesomeIcon icon={faVolleyballBall} />
            <span>{translate(SportEnum.VOLLEYBALL, l)}</span>
          </Button>
        </div>
      </div>
    </div>
  );
};
SportsTypePicker.displayName = "SportsTypePicker";
export default connector(SportsTypePicker);
