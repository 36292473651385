import React, { useContext } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowAltCircleDown,
  faArrowAltCircleUp,
} from "@fortawesome/pro-regular-svg-icons";
import { ChangeEvent } from "../../../../client/api";
import { translate } from "../../../../config/translator";
import { ClavaContext } from "../../../../config/contexts";

const ChangeEventView: React.FC<{ change: ChangeEvent; right?: boolean }> = ({
  change,
  right,
}) => {
  const { l } = useContext(ClavaContext);
  return (
    <div className="change-event py-1">
      <div className="minute">
        <span>{`${change.minute ?? translate("noMinutesShort", l)}'`}</span>
      </div>
      <div className="icons">
        <FontAwesomeIcon icon={faArrowAltCircleDown} color="#f00" />
        <br />
        <FontAwesomeIcon icon={faArrowAltCircleUp} color="#0f0" />
      </div>
      <div className="change-player">
        <span>
          {change.player
            ? `${change.player.familyName} ${change.player.givenName.slice(
                0,
                1
              )}.`
            : "..."}
        </span>
        <br />
        <span>
          {change.playerIn
            ? `${change.playerIn.familyName} ${change.playerIn.givenName.slice(
                0,
                1
              )}.`
            : "..."}
        </span>
      </div>
    </div>
  );
};

ChangeEventView.defaultProps = {
  right: false,
};

export default ChangeEventView;
