import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { connect } from "react-redux";
import { RootState } from "../../../../../store";
import { IDType } from "../../../../../config/types";
import { MoveEnum, SportEnum } from "../../../../../client/api";
import { performAction } from "../../../../../store/actions/all";
import { moveLeague } from "../../../../../store/actions/leagueActions";

const mapper = (dispatch: ThunkDispatch<any, any, AnyAction>) => ({
  onMove: (id: IDType, direction: MoveEnum, aoi: IDType, sports: SportEnum) => {
    performAction({ f: moveLeague, p: [dispatch, id, direction, aoi, sports] });
  },
});

const props = (state: RootState) => ({
  leagues: state.leagues.value,
  sports: state.user.sportsType,
});

export const connector = connect(props, mapper);
