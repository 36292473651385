import React, { useMemo } from "react";
import { File, Image } from "../../../client/api";
import { getFileUrl } from "../../../config/utils";

const ClavaImage: React.FC<{
  image: File | Image;
  width: number | string;
  className?: string;
  isEditable?: boolean;
}> = ({ image, width, isEditable, className }) => {
  const w = useMemo(() => {
    if (typeof width === "string" && width.indexOf("%") !== -1) {
      const percent = parseInt(width, 10);
      return window.innerWidth * (percent / 100);
    }
    let wi = parseInt(width.toString(), 10);
    if (Number.isNaN(wi)) {
      wi = 1920;
    }
    return wi;
  }, [width]);
  const srcSet = useMemo(() => {
    if ("formats" in image) {
      const { formats, url, caption } = image;
      return formats
        .map((format) => `${format.url} ${format.width}w`)
        .join(", ");
    }
    return undefined;
  }, [image]);
  const url = useMemo(
    () =>
      "formats" in image
        ? getFileUrl(image, w)
        : `${image.url.replace("/public", "")}/w=${w}`,
    []
  );
  return (
    <img
      src={url}
      srcSet={srcSet}
      key={`image-${image.id}`}
      width={width}
      className={`img ${isEditable ? "editable" : ""} ${className}`}
      alt={"caption" in image ? image.caption : image.id}
    />
  );
};

ClavaImage.defaultProps = {
  isEditable: false,
  className: undefined,
};
export default ClavaImage;
